import { Box, Button, Icon, IconButton, Input, InputGroup, InputLeftElement, Stack, Table, 
    TableContainer, Tag, TagLabel, Tbody, Td, Text, Thead, Tr, useColorMode, useToast, Spinner, Tfoot, Select } from '@chakra-ui/react';
import { FaSearch } from "react-icons/fa";
import { FiPlus } from 'react-icons/fi';
import { AiOutlineEye } from 'react-icons/ai';
import { MdDeleteOutline } from 'react-icons/md'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import DeleteConfirmationDialog from '../../components/Dialogs/DeleteConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';
import { url } from '../../assets/urlAdress';
import { GrPowerCycle } from 'react-icons/gr';

interface Car {
  _id: any;
  name: string;
  number: number;
  fuelExpenses: number;
  faultsExpenses: number;
  currHolder: String;
  status: Boolean;
  expenses: any;
  usage: any;
}

function Cars() {
  const { setIsAuthenticated } = useAuth();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${url}/cars`, { withCredentials: true });
      const data = response.data;
      setCars(data);
      setIsLoading(false);
    } catch (error:any) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      if (error?.response?.status === 401) { setIsAuthenticated(false); }
    }
  };  
  useEffect(() => {
      fetchData();
  }, []);

  const toast = useToast()
  const [cars, setCars] = useState<Car[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);

  const navigate = useNavigate();

  const handleCreateCarButtonClick = () => {
    navigate('/cars/createCar');
  };

  const handleDeleteButtonClick = async (itemId: any) => {
    try {
        const response = await axios.delete(`${url}/cars/${itemId}`, { withCredentials: true });
        if (response.status === 200) {
          toast({
            title: 'רכב נמחק',
            status: 'success',
            duration: 5000,
            position: 'top-right',
            isClosable: true,
          });
          fetchData();
        }
    } catch (error:any) {
        console.error('Error creating car:', error);
        toast({
          title: 'Error',
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
    }
  }

  const { colorMode  } = useColorMode();
  const headerColor = colorMode === "dark" ? "black" : "#f6f6f6f6";
  const [searchInput, setSearchInput] = useState('');

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [currentId, setCurrentId] = useState(false);

  // Function to calculate total expenses
  const fuelConsumptionTotal = cars.reduce((total, car) => {
    const carTotal = car.usage.reduce((usageSum:Number, usage:any) => {
      if((month !== null && month !== 0 && usage.Date.month !== month)
        || (year !== null && year !== 0 && usage.Date.year !== year)) {
        return usageSum;
      }
      return usageSum + usage.fuelConsumption; 
    }, 0);
    return total + carTotal;
  }, 0);

  const expensesTotal = cars.reduce((total, car) => {
    const expensesTotal = car.expenses.reduce((expensesSum:number, expense:any) => {
      if((month !== null && month !== 0 && expense.Date.month !== month)
        || (year !== null && year !== 0 && expense.Date.year !== year) ||
        !car.number.toString().toLowerCase().includes(searchInput.toLowerCase().trim()) ||
        !car.number.toString().toLowerCase().includes(searchInput.toLowerCase().trim())) {
        return expensesSum;
      }
      return expensesSum + expense.Price * expense.quantity * (1-(expense.deduction/100)); 
    }, 0);
    return total + expensesTotal;
  }, 0);

  return (
    <Box dir="rtl">
        {isLoading ? (
            <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                padding="2rem"
                borderRadius="8px"
                zIndex="1000"
            >
               <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
            </Box>
        )
        : (
          <Box>
                <Box>
            <Text fontSize='3xl' textAlign='center' fontWeight="bold">רכבים</Text><br/>
            <Stack spacing={2} direction='row'>
                <InputGroup mr={3}>
                    <InputLeftElement><Icon as={FaSearch} /></InputLeftElement>
                    <Input placeholder='חיפוש' onChange={(e) => setSearchInput(e.target.value)}/>
                </InputGroup>
                <Button mr={2} colorScheme='teal' sx={{borderRadius: 50}} onClick={handleCreateCarButtonClick}>
                    <Icon as={FiPlus} sx={{fontSize: '20'}}/>
                    <Text sx={{ fontFamily:'Arial', display: {base: 'none', sm: 'block'} }}>הוספת רכב</Text>
                </Button>
                <Button mr={2} colorScheme='teal' sx={{borderRadius: 50}} onClick={() => navigate('/Cars/CarUsageAllCars')}>
                    <Icon as={FiPlus} sx={{fontSize: '20'}}/>
                    <Text sx={{ fontFamily:'Arial', display: {base: 'none', sm: 'block'} }}>הוספת שימוש</Text>
                </Button>
            </Stack>
        </Box><br/>
        <TableContainer pr={3}>
            <Table variant='simple'>
                <Thead sx={{bgColor: headerColor}}>
                    <Tr>
                        <Td fontWeight="bold">שם רכב</Td>
                        <Td fontWeight="bold">מספר רכב</Td>
                        <Td fontWeight="bold">הוצאות דלק</Td>
                        <Td fontWeight="bold">הוצאות תיקונים</Td>
                        <Td fontWeight="bold">מחזיק נוכחי</Td>
                        <Td fontWeight="bold">מצב</Td>
                        <Td fontWeight="bold">פעולות</Td>                                                                                               
                    </Tr>
                </Thead>
                <Tbody>{cars.filter((car) => {
                    return (
                        car.name.toLowerCase().includes(searchInput.toLowerCase().trim()) ||
                        car.number.toString().toLowerCase().includes(searchInput.toLowerCase().trim())
                    );
                }).map((car) => (
                    <>
                    <Tr>           
                      <Td>{car.name}</Td>    
                      <Td>{car.number}</Td>
                      <Td>{car.fuelExpenses}₪</Td>
                      <Td>{car.faultsExpenses}₪</Td>
                      <Td>{car.currHolder}</Td>
                      <Td>
                          <Tag
                            size='lg'
                            colorScheme={ car.status === true ? 'green' : 'red'}
                            borderRadius='full'>
                            <TagLabel>
                              {car.status === true ? 'זמינה' : 'בתיקון'}
                            </TagLabel>
                          </Tag>
                      </Td>
                      <Td>
                        <Stack spacing={3} direction='row'>
                            <IconButton
                             variant='solid'
                             colorScheme='teal'
                             aria-label='Call Sage'
                             fontSize='20px'
                             icon={<AiOutlineEye />}
                             onClick={() => navigate(`/cars/${car._id}`)}
                           />
                           <IconButton
                             colorScheme='red'
                             aria-label='Call Sage'
                             fontSize='20px'
                             icon={<MdDeleteOutline/>}
                             onClick={() => setCurrentId(car._id)}
                           />
                        </Stack>
                      </Td>
                    </Tr>
                    {currentId === car._id && <DeleteConfirmationDialog 
                      title={`:האם אתה בטוח שרוצה למחוק רכב ${car.name} ,שמספרה: ${car.number}`}
                      isOpen={currentId}
                      onClose={() => setCurrentId(false)}
                      yesFunction={handleDeleteButtonClick}
                      itemId={car._id}
                    />}
                    </>
                ))}</Tbody>
                <Tfoot>
                  <Tr>
                    <Td></Td>
                    <Td fontWeight='bold'>סכום:</Td>
                    <Td fontWeight="bold">{fuelConsumptionTotal}₪</Td>
                    <Td fontWeight="bold">{expensesTotal * 1.17}₪</Td>
                    <Td>
                      <Input placeholder="שנה" value={year !== 0 ? year : ''} onChange={(e)=> setYear(Number(e.target.value))}/>
                    </Td>
                    <Td>
                      <Select value={month} dir='ltr' onChange={(e:any)=> setMonth(Number(e.target.value))}>
                        <option value={0}>חודש</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                      </Select>
                    </Td>
                    <Td><IconButton onClick={() => {setMonth(0); setYear(0);}} bgColor='white' aria-label='Call Sage' fontSize='20px' icon={<GrPowerCycle/>}/></Td>
                  </Tr>
                </Tfoot>

            </Table>
        </TableContainer>
            </Box>
        )}
        <DeleteConfirmationDialog title={'title test'} isOpen={deleteDialog} onClose={()=>setDeleteDialog(false)} yesFunction={() => {}} itemId={'123456'}/>
    </Box>
  )
}

export default Cars;
