import { Box, Button, Flex, Heading, Icon, IconButton, Input, Select, Spinner, Stack, Tab, TabList, TabPanel, TabPanels,
   Table, TableContainer, Tabs, Tag, TagLabel, Tbody, Td, Text, Thead, Tooltip, Tr, useColorMode, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { FaMoneyBillAlt, FaCalendarDay, FaUmbrellaBeach, FaEdit  } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';
import AddRestDayModal from '../../components/Modals/WorkerModals/addRestDayModal';
import WorkerPaymentModal from '../../components/Modals/WorkerModals/workerPaymentModal';
import AddWorkDayModal from '../../components/Modals/WorkerModals/addWorkDay';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import DeleteConfirmationDialog from '../../components/Dialogs/DeleteConfirmationDialog';
import { useAuth } from '../../Auth/AuthContext';
//import EditPaymentModal from '../../components/Modals/WorkerModals/editModals/EditPaymentModal';
import { url } from '../../assets/urlAdress';
import { GrPowerCycle } from 'react-icons/gr';

interface Worker {
  _id: any;
  name: string;
  phoneNumber: string;
  dailyPayment: number;
  status: String;
  currIncome: number;
  currentWorkSite: any;
  salaries: any;
  workDays: any;
  daysOff: any;
}

function WorkerDetails() {
  const { setIsAuthenticated } = useAuth();
  const workerId = useParams();
  const [worker, setWorker] = useState<Worker>();
  const toast = useToast()
  const navigate = useNavigate();

  const { colorMode  } = useColorMode();
  const headerColor = colorMode === "dark" ? "black" : "#EFEFEFF6";

  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${url}/Workers/${workerId.id}`, { withCredentials: true });
      const data = response.data;
      console.log(data)
      setWorker(data);
      setIsLoading(false);
    } catch (error:any) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      if (error?.response?.status === 401) { setIsAuthenticated(false); }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [addRestDayModal, setAddRestDayModal] = useState(false)
  const [workerPaymentModalModal, setWorkerPaymentModalModal] = useState(false)
  const [addWorkDayModal, setAddWorkDayModal] = useState(false)

  const [currentPaymentDeleteId, setCurrentPaymentDeleteId] = useState(false);
  {/*const [currentPaymentEditId, setCurrentPaymentEditId] = useState(false);*/}
  const [currentWorkDayId, setCurrentWorkDayId] = useState(false);
  const [currentDayOffId, setCurrentDayOffId] = useState(false);

  const [fromDay, setFromDay] = useState(0);
  const [fromMonth, setFromMonth] = useState(0);
  const [fromYear, setFromYear] = useState(0);

  const [toDay, setToDay] = useState(0);
  const [toMonth, setToMonth] = useState(0);
  const [toYear, setToYear] = useState(0);

  const fromDate = new Date(fromYear, fromMonth - 1, fromDay);
  const toDate = new Date(toYear, toMonth - 1, toDay);

  const handleDeletePaymentSubmission = async (itemId: any) => {
    try {
      const response = await axios.delete(`${url}/Workers/${workerId.id}/salaries/delete/${itemId}`
      , { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'משכורת נמחקה בהצלחה',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        fetchData();
      }
  } catch (error:any) {
    console.error('Error creating car:', error);
    toast({
      title: 'Error',
      status: 'error',
      duration: 5000,
      position: 'top-right',
      isClosable: true,
    });
  }
  }
  const handleDeleteWorkDaySubmission = async (itemId:any) => {
    try {
      const response = await axios.delete(`${url}/Workers/${workerId.id}/daysOff/deleteDayOff/${itemId}`
      , { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'יום חופשה נמחקה בהצלחה',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        fetchData();
      }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'Error',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }
  const handleDeleteDayOffSubmission = async (itemId:any) => {
    try {
      const response = await axios.delete(`${url}/Workers/${workerId.id}/WorkDay/deleteWorkDay/${itemId}`
      , { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'יום עבודה נמחקה בהצלחה',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        fetchData();
      }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'Error',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const sumOfWorkDays = worker?.workDays.reduce((sum:any, workDay:any) => sum + workDay.payment, 0);

  return (
    <Box dir='rtl'>
      {isLoading ? (
        <Box
            position="fixed"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            padding="2rem"
            borderRadius="8px"
            zIndex="1000"
        >
           <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
        </Box>
      ) : ( <>
      <AddRestDayModal title={`הוספת יום חופש ל${worker?.name}`} isOpen={addRestDayModal} onClose={ () => setAddRestDayModal(false) } workerId={worker?._id} yesFunction={fetchData} />
      <WorkerPaymentModal 
        title={`הוספת משכורת ל${worker?.name}`}
        isOpen={workerPaymentModalModal}
        onClose={() => setWorkerPaymentModalModal(false)}
        workerId={worker?._id}
        yesFunction={fetchData}
        currSalary={Number(worker?.dailyPayment)}
      />
      <AddWorkDayModal 
        title={`הוספת יום עבודה ל${worker?.name}`} 
        isOpen={addWorkDayModal} 
        onClose={() => setAddWorkDayModal(false)} 
        workerId={worker?._id} 
        currWorkSite={worker?.currentWorkSite} 
        dayPayment={worker?.dailyPayment || 0} 
        yesFunction={fetchData}
      />
      <Flex justifyContent="space-between">
        <Heading as='h3' size='lg'>{worker?.name}</Heading>
        <Stack direction={{ base: 'column', md: 'row' }} spacing={3}>
          <Stack direction='row' spacing={3}>
            <Button sx={{ borderRadius: 35 }} onClick={() => navigate(`/Workers/Edit/${workerId.id}`)}>
              <Icon as={FaEdit} sx={{fontSize: '20'}}/>
              <Text sx={{ fontFamily: 'Arial', mr: 2, display: {base: 'none', sm: 'block'}}}>עריכה</Text>
            </Button>
            <Button sx={{ borderRadius: 35 }} onClick={() => setWorkerPaymentModalModal(true)}>
              <Icon as={FaMoneyBillAlt} sx={{fontSize: '20'}}/>
              <Text sx={{ fontFamily: 'Arial', mr: 2, display: {base: 'none', sm: 'block'}}}>הוספת משכורת</Text>
            </Button>
          </Stack>
          <Stack direction='row' spacing={3}>
            <Button sx={{ borderRadius: 35 }} onClick={() => setAddWorkDayModal(true)}>
              <Icon as={FaCalendarDay} sx={{fontSize: '20'}}/>
              <Text sx={{ fontFamily: 'Arial', mr: 2, display: {base: 'none', sm: 'block'}}}>הוספת יום עבודה</Text>
            </Button>
            <Button sx={{ borderRadius: 35 }} onClick={ () => setAddRestDayModal(true) }>
              <Icon as={FaUmbrellaBeach} sx={{fontSize: '20'}}/>
              <Text sx={{ fontFamily: 'Arial', mr: 2, display: {base: 'none', sm: 'block'}}}>הוספת יום חופש</Text>
            </Button>
          </Stack>
        </Stack>
      </Flex><br/>
      <Flex justifyContent="space-between" direction={{ base: 'column', md: 'row' }}>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>מספר טל: {worker?.phoneNumber}</Text>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>יומית: {worker?.dailyPayment}₪</Text>
        <Tag
          size='lg'
          colorScheme={worker?.status === 'ableToWork' ? 'green' : 'red'}
          borderRadius='full'>
          <TagLabel>
            מצב: {worker?.status === 'ableToWork' ? 'זמין' : 'חופש'}
          </TagLabel>
        </Tag>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>א.בניה נוכחי: {worker?.currentWorkSite?.name || "אין"}</Text>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>נשאר לשלם: {worker?.currIncome}₪</Text>
      </Flex><br/>
      <Tabs isFitted>
        <TabList>
          <Tab>ימי עבודה</Tab>
          <Tab>משכורות</Tab>
          <Tab>ימי חופשה</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Stack spacing={2} direction='row'>
              <Stack spacing={2} direction='row'>
                <Input placeholder="משנה" value={fromYear !== 0 ? fromYear : ''} onChange={(e)=> setFromYear(Number(e.target.value))}/>
                <Select value={fromMonth} dir='ltr' onChange={(e)=> setFromMonth(Number(e.target.value))}>
                  <option value={0}>מחודש</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                </Select>
                <Input placeholder="מיום" value={fromDay !== 0 ? fromDay : ''} onChange={(e)=> setFromDay(Number(e.target.value))}/>
              </Stack>
              <Stack spacing={2} direction='row'>
                <Input placeholder="לשנה" value={toYear !== 0 ? toYear : ''} onChange={(e)=> setToYear(Number(e.target.value))}/>
                <Select value={toMonth} dir='ltr' onChange={(e)=> setToMonth(Number(e.target.value))}>
                  <option value={0}>לחודש</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                </Select>
                <Input placeholder="ליום" value={toDay !== 0 ? toDay : ''} onChange={(e)=> setToDay(Number(e.target.value))}/>
                <IconButton onClick={() => {setFromMonth(0); setFromYear(0); setFromDay(0); setToMonth(0); setToYear(0); setToDay(0);}} bgColor='white' aria-label='Call Sage' fontSize='20px' icon={<GrPowerCycle/>}/>
              </Stack>
            </Stack><br/>
            <Table>
              <Thead sx={{bgColor: headerColor}}>
                <Tr>
                  <Td fontWeight="bold">תאריך</Td>
                  <Td fontWeight="bold">תשלום</Td>
                  <Td fontWeight="bold">א.בניה</Td>
                  <Td fontWeight="bold">תיאור</Td>
                  <Td fontWeight="bold">פעולות</Td>
                </Tr>
              </Thead>
              <Tbody>
                {worker?.workDays.map((workDay:any) => (
                  <Tr key={workDay._id}>
                    <Td>{workDay.Date.day}/{workDay.Date.month}/{workDay.Date.year}</Td>
                    <Td>{workDay.payment}</Td>
                    <Td>{workDay?.workSite?.name || 'אין'}</Td>
                    <Td>
                      <Tooltip
                        label={
                          <Box dir="rtl">
                            {workDay?.description}
                          </Box>
                        }
                        fontSize="md"
                        hasArrow arrowSize={15}
                        placement='bottom-end'>
                        <Text fontWeight="bold" fontSize="lg" fontFamily="arial">תיאור</Text>
                      </Tooltip>
                    </Td>
                    <Td>
                      <Stack spacing={3} direction='row'>
                        <IconButton
                          colorScheme='red'
                          aria-label='Call Sage'
                          fontSize='20px'
                          icon={<MdDeleteOutline />}
                          onClick={() => setCurrentWorkDayId(workDay._id)}
                        />
                      </Stack>
                    </Td>
                    {currentWorkDayId === workDay._id && <DeleteConfirmationDialog
                      title={`האם אתה רוצה למחוק יום עבודה בתאריך ${workDay.Date.day}/${workDay.Date.month}/${workDay.Date.year}`}
                      isOpen={currentWorkDayId}
                      onClose={() => setCurrentWorkDayId(false)}
                      yesFunction={handleDeleteDayOffSubmission}
                      itemId={workDay._id}
                    />}
                  </Tr>
                ))}
                <Tr>
                  <Td></Td>
                  <Td fontWeight='bold'>סכום:</Td>
                  <Td fontWeight='bold'>{sumOfWorkDays}₪</Td>
                </Tr>
              </Tbody>
            </Table>
          </TabPanel>
          <TabPanel>
            <Stack spacing={2} direction='row'>
              <Stack spacing={2} direction='row'>
                <Input placeholder="משנה" value={fromYear !== 0 ? fromYear : ''} onChange={(e)=> setFromYear(Number(e.target.value))}/>
                <Select value={fromMonth} dir='ltr' onChange={(e)=> setFromMonth(Number(e.target.value))}>
                  <option value={0}>מחודש</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                </Select>
                <Input placeholder="מיום" value={fromDay !== 0 ? fromDay : ''} onChange={(e)=> setFromDay(Number(e.target.value))}/>
              </Stack>
              <Stack spacing={2} direction='row'>
                <Input placeholder="לשנה" value={toYear !== 0 ? toYear : ''} onChange={(e)=> setToYear(Number(e.target.value))}/>
                <Select value={toMonth} dir='ltr' onChange={(e)=> setToMonth(Number(e.target.value))}>
                  <option value={0}>לחודש</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                </Select>
                <Input placeholder="ליום" value={toDay !== 0 ? toDay : ''} onChange={(e)=> setToDay(Number(e.target.value))}/>
                <IconButton onClick={() => {setFromMonth(0); setFromYear(0); setFromDay(0); setToMonth(0); setToYear(0); setToDay(0);}} bgColor='white' aria-label='Call Sage' fontSize='20px' icon={<GrPowerCycle/>}/>
              </Stack>
            </Stack><br/>
            <TableContainer pr={3}>
              <Table variant='simple'>
                <Thead sx={{bgColor: headerColor}}>
                  <Tr>
                    <Td fontWeight="bold">משכורת</Td>
                    <Td fontWeight="bold">מתאריך</Td>
                    <Td fontWeight="bold">עד תאריך</Td>
                    <Td fontWeight="bold">פעולות</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {worker?.salaries.map((salary: any) => (
                    <Tr key={salary._id}>
                      <Td>{salary.salary}₪</Td>
                      <Td>{salary.fromDate.day}/{salary.fromDate.month}/{salary.fromDate.year}</Td>
                      <Td>{salary.toDate.day}/{salary.toDate.month}/{salary.toDate.year}</Td>
                      <Td>
                        {/*<Stack spacing={3} direction='row'>
                          <IconButton
                            colorScheme='teal'
                            aria-label='Call Sage'
                            fontSize='20px'
                            icon={<FaEdit />}
                            onClick={() => setCurrentPaymentEditId(salary._id)}
                        />*/}
                          <IconButton
                            colorScheme='red'
                            aria-label='Call Sage'
                            fontSize='20px'
                            icon={<MdDeleteOutline />}
                            onClick={() => setCurrentPaymentDeleteId(salary._id)}
                          />
                        {/* </Stack> */}
                      </Td>
                      {currentPaymentDeleteId === salary._id && <DeleteConfirmationDialog
                        title={`האם אתה רוצה למחוק המשכורת מתאריך ${salary.fromDate.day}/${salary.fromDate.month}/${salary.fromDate.year} לתאריך: ${salary.toDate.day}/${salary.toDate.month}/${salary.toDate.year} ?`}
                        isOpen={currentPaymentDeleteId}
                        onClose={() => setCurrentPaymentDeleteId(false)}
                        yesFunction={handleDeletePaymentSubmission}
                        itemId={salary._id}
                      />}
                      {/*currentPaymentEditId === salary._id && <EditPaymentModal
                        title={'שינוש תשלום המשכורת'} 
                        isOpen={currentPaymentEditId} 
                        onClose={() => setCurrentPaymentEditId(false)} 
                        workerId={worker?._id} 
                        salary={salary.salary} 
                        currPayment={salary.paid}
                        itemId={salary._id}
                        yesFunction={()=>{}}
                      />*/}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <Table>
              <Thead sx={{bgColor: headerColor}}>
                <Td fontWeight="bold">מחיקה</Td><Td fontWeight="bold">תאריך</Td><Td fontWeight="bold">מצב</Td>
              </Thead>
              <Tbody>
                {worker?.daysOff.map((dayOff:any) => (
                  <Tr>
                    <Td>
                      <IconButton
                        colorScheme='red'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<MdDeleteOutline />}
                        onClick={() => setCurrentDayOffId(dayOff._id)}
                      />
                    </Td>
                    <Td>{dayOff.date.day}/{dayOff.date.month}/{dayOff.date.year}</Td>
                    <Td>
                      <Tag
                        size='lg'
                        colorScheme={dayOff.status === 'vacation' ? 'orange' : 'red'}
                        borderRadius='full'>
                        <TagLabel>
                          {dayOff.status === 'vacation' ? 'חופשה' : 'מחלה'}
                        </TagLabel>
                      </Tag>
                    </Td>
                    {currentDayOffId === dayOff._id && <DeleteConfirmationDialog
                      title={`האם אתה רוצה למחוק יום חופשה בתאריך: ${dayOff.date.day}/${dayOff.date.month}/${dayOff.date.year}`}
                      isOpen={currentDayOffId}
                      onClose={() => setCurrentDayOffId(false)}
                      yesFunction={handleDeleteWorkDaySubmission}
                      itemId={dayOff._id}
                    />}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TabPanel>
        </TabPanels>
      </Tabs>
      </>)}
    </Box>
  )
}

export default WorkerDetails;
