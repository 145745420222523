import { Box, Spinner, Table, Tbody, Td, Thead, Tr, Text, Stack, InputGroup, InputLeftElement, Input, Button, Icon, useToast, TagLabel, Tag, IconButton, useColorMode, Flex, Select } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import { GrPowerCycle } from 'react-icons/gr';
import { MdDeleteOutline } from 'react-icons/md';
import AddProjectPaymentModal from '../components/Modals/ProjectModals/addProjectPaymentModal';
import DeleteConfirmationDialog from '../components/Dialogs/DeleteConfirmationDialog';
import { useAuth } from '../Auth/AuthContext';

import { url } from '../assets/urlAdress';

function Checks() {
    const { setIsAuthenticated } = useAuth();
  const toast = useToast();
  const { colorMode  } = useColorMode();
  const headerColor = colorMode === "dark" ? "black" : "#EFEFEFF6";
  const [checks, setChecks] = useState<any[]>();
  const [isLoading, setIsLoading] = useState(true);
  
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${url}/Checks`, { withCredentials: true });
      const data = response.data;
      setChecks(data)
      setIsLoading(false);
    } catch (error:any) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      if (error?.response?.status === 401) { setIsAuthenticated(false); }
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  const [addPaymentModal, setAddPaymentModal] = useState(false)
  const [currId, setCurrId] = useState(false);
  const [search, setSearch] = useState('');
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);
  
  const handleDeleteButtonClick = async (itemId:any) => {
    try {
        const response = await axios.delete(`${url}/Checks/${itemId}`, { withCredentials: true });
        if (response.status === 200) {
          toast({
            title: 'צי"ק נמחק',
            status: 'success',
            duration: 5000,
            position: 'top-right',
            isClosable: true,
          });
          fetchData();
        }
    } catch (error:any) {
        console.error('Error creating car:', error);
        toast({
          title: 'Error',
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
    }
  }

  const sumOfChecks = checks?.filter((check) => {
    if (month !== null && month !== 0 && check.Date.month !== month) {
      return false;
    }
    if (year !== null && year !== 0 && check.Date.year !== year) {
      return false;
    }
    return check.number.toString().toLowerCase().includes(search.toLowerCase());
  }).reduce((sum, check) => sum + check.price, 0);


  {/*const handleToggleSubmit = async (itemId:any) => {
    try {
        console.log(itemId)
      const response = await axios.patch(`http://localhost:5000/Checks/${itemId}`, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'צי"ק עודכן',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        fetchData();
      }
    } catch (error) {
      console.error('Error toggling check:', error);
      toast({
        title: 'Error',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }*/}
  
  return (
    <Box dir='rtl'>
      {isLoading ? (
            <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                padding="2rem"
                borderRadius="8px"
                zIndex="1000"
            >
              <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
            </Box>
        ) : (
            <Box>
                <AddProjectPaymentModal url={`${url}/Checks`} ifJustCheck={true}
                    title={'הוספת צ"יק'} isOpen={addPaymentModal} onClose={() => setAddPaymentModal(false)} yesFunction={fetchData}/>
                <Flex justify='space-between' mr={7} ml={4}>
                  <Text fontSize='3xl' textAlign='center' fontWeight="bold">צ"יקים</Text><br/>
                  <Button mr={2} colorScheme='teal' sx={{borderRadius: 50}} onClick={() => setAddPaymentModal(true)}>
                    <Icon as={FiPlus} sx={{fontSize: '20'}}/>
                    <Text sx={{ fontFamily:'Arial', display: {base: 'none', sm: 'block'} }}>הוספת צ"יק</Text>
                  </Button>
                </Flex><br/>
                <Stack spacing={2} direction='row'>
                  <InputGroup mr={3}>
                    <InputLeftElement><Icon as={FaSearch} /></InputLeftElement>
                    <Input placeholder='חיפוש' onChange={(e) => setSearch(e.target.value)}/>
                  </InputGroup>
                  <Input placeholder="שנה" value={year !== 0 ? year : ''} onChange={(e)=> setYear(Number(e.target.value))}/>
                  <Select value={month} dir='ltr' onChange={(e)=> setMonth(Number(e.target.value))}>
                    <option value={0}>חודש</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                    <option value={11}>11</option>
                    <option value={12}>12</option>
                  </Select>
                  <IconButton onClick={() => {setMonth(0); setYear(0);}} bgColor='white' aria-label='Call Sage' fontSize='20px' icon={<GrPowerCycle/>}/>
                </Stack> <br/>
                <Table>
                    <Thead bgColor={headerColor}>
                        <Tr>
                            <Td fontWeight='bold'>מספר צ"יק</Td>
                            <Td fontWeight='bold'>בנק</Td>
                            <Td fontWeight='bold'>חשבון</Td>
                            <Td fontWeight='bold'>תאריך</Td>
                            <Td fontWeight='bold'>סכום</Td>
                            <Td fontWeight='bold'>איש</Td>
                            {/* <Td fontWeight='bold'>מצב</Td> */}
                            <Td fontWeight='bold'>פעולות</Td>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {checks?.filter((check) => {
                          if (month !== null && month !== 0 && check.Date.month !== month) {
                            return false;
                          }
                          if (year !== null && year !== 0 && check.Date.year !== year) {
                            return false;
                          }
                          return(
                            check.number.toString().toLowerCase().includes(search.toLowerCase())
                          );
                        })
                        .map((check) => (
                            <Tr key={check._id}>
                                <Td>{check.number}</Td>
                                <Td>{check.bank} - {check.branch}</Td>
                                <Td>{check.account}</Td>
                                <Td>{check.Date.day}/{check.Date.month}/{check.Date.year}</Td>
                                <Td>{check.price}₪</Td>
                                <Td>{check.person}</Td>
                                {/* <Td>
                                    <Tag
                                      size="lg"
                                      colorScheme={check.paid ? 'green' : 'red'}
                                      borderRadius="full">
                                      <TagLabel>
                                        {check.ifPayed ? 'שולם' : 'לא שולם'}
                                      </TagLabel>
                                    </Tag>
                        </Td> */}
                                <Td>
                                <Stack spacing={3} direction='row'>
                                  {/* <IconButton
                                    colorScheme='green'
                                    aria-label='Call Sage'
                                    fontSize='20px'
                                    icon={<GrPowerCycle />}
                                    onClick={() => handleToggleSubmit(check._id)}
                                  /> */}
                                  <IconButton
                                    colorScheme='red'
                                    aria-label='Call Sage'
                                    fontSize='20px'
                                    icon={<MdDeleteOutline />}
                                    onClick={()=>setCurrId(check._id)}
                                  />
                                </Stack>
                                </Td>
                                {currId === check._id && <DeleteConfirmationDialog
                                   title={`האם אתה רוצה למחוק הצ"יק מספר: ${check.number}`}
                                   isOpen={currId}
                                   onClose={() => setCurrId(false)}
                                   yesFunction={handleDeleteButtonClick}
                                   itemId={check._id}
                                 />}
                            </Tr>
                        ))}
                        <Tr>
                          <Td colSpan={4}></Td>
                          <Td fontWeight='bold'>סכום:</Td>
                          <Td fontWeight='bold'>{sumOfChecks}₪</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Box>
        )}
    </Box>
  )
}

export default Checks
