import React, { useState } from 'react'
import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, FormControl, FormLabel, Stack, Input, Select, useBreakpointValue, useToast, Spinner } from '@chakra-ui/react'
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface AddProjectPaymentProps {
  title: string;
  isOpen: boolean;
  url: any;
  ifJustCheck: Boolean;
  onClose: () => void;
  yesFunction: () => void;
}


function AddProjectPaymentModal({ title, isOpen, onClose, yesFunction, url, ifJustCheck }:AddProjectPaymentProps) {
  const modalSize = useBreakpointValue({ base: "full", md: "3xl" });
  const projectId = useParams();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState('');
  const [bank, setBank] = useState('');
  const [branch, setBranch] = useState('');
  const [checkNumber, setCheckNumber] = useState('');
  const [account, setAccount] = useState('');
  const [price, setPrice] = useState(0);
  const [person, setPerson] = useState('');

  const [day, setDay] = useState((new Date()).getDate());
  const [month, setMonth] = useState((new Date()).getMonth() + 1);
  const [year, setYear] = useState((new Date()).getFullYear());

  const handleSubmit = async () => {
    try {
      setIsLoading(true); 
      const response = await axios.post(url, {
        paymentType: paymentMethod,
        paymentDetails: {
          number: checkNumber,
          bank: bank,
          branch: branch,
          account: account,
          date: {day: day, month: month, year: year},
          price: price,
          person: person,
        }
      }, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'תשלום הוסף',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        yesFunction();
        onClose()
      }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'חוסר/טעות בנתונים',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    } finally { setIsLoading(false); }
  }

  return (
    <Box>
        <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontSize={{base: 'medium', md: 'x-large'}} display="flex" justifyContent="center" alignItems="center" fontWeight="bold">{title}</ModalHeader>
              <ModalCloseButton />
                <ModalBody dir='rtl'>
                  {!ifJustCheck && ( <>
                  <FormControl>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">סוג תשלום</FormLabel>
                    <Select value={paymentMethod} onChange={(e)=>setPaymentMethod(e.target.value)} dir='ltr' placeholder="תבחור ספאק" size='lg'>
                      <option value='check'>צי"ק</option>
                      <option value='bankTransfer'>העברה בנקאית</option>
                    </Select>
                  </FormControl><br/> </>)}
                  {(paymentMethod === 'check' || ifJustCheck) && 
                  (<>
                      <FormControl>
                          <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מספר צי"ק</FormLabel>
                          <Input value={checkNumber} type='text' onChange={(e)=>setCheckNumber(e.target.value)}/>
                      </FormControl><br/>
                    </>
                  )}
                  <Stack justify='space-around' direction={{base: 'column', md: 'row'}} spacing={{base: 2, md: 3}}>
                    <FormControl>
                        <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
                            בנק
                        </FormLabel>
                      <Input value={bank} type='text' onChange={(e)=>setBank(e.target.value)}/>
                    </FormControl>
                    <FormControl>
                        <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
                            סניף
                        </FormLabel>
                      <Input value={branch} type='text' onChange={(e)=>setBranch(e.target.value)}/>
                    </FormControl>
                  </Stack><br/>
                  <FormControl>
                      <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
                          חשבון
                      </FormLabel>
                    <Input value={account} type='text' onChange={(e)=>setAccount(e.target.value)}/>
                  </FormControl><br/>
                  <FormControl>
                      <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">תאריך</FormLabel>
                      <Stack spacing={4} direction={{ base: 'column-reverse', md: 'row'}} >
                          <Stack direction='row'>
                              <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שנה:</FormLabel>
                              <Input value={year} type='number' onChange={(e:any)=>setYear(e.target.value)}/>
                          </Stack>
                          <Stack direction='row'>
                              <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חודש:</FormLabel>
                              <Input value={month} type='number' onChange={(e:any)=>setMonth(e.target.value)}/>
                          </Stack>
                          <Stack direction='row'>
                              <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יום:</FormLabel>
                              <Input value={day} type='number' onChange={(e:any)=>setDay(e.target.value)}/>
                          </Stack>
                      </Stack>
                  </FormControl><br/>
                  <FormControl>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">סכום</FormLabel>
                    <Input value={price} type='number' onChange={(e)=>setPrice(Number(e.target.value))}/>
                  </FormControl><br/>
                  <FormControl>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">איש</FormLabel>
                    <Input value={person} name='text' type='text' onChange={(e)=>setPerson(e.target.value)}/>
                  </FormControl>
                </ModalBody>
              <ModalFooter>
                {isLoading ? ( // Show the loading spinner while loading
                  <Spinner color="green.500" size="xl" thickness="5px"/>
                ) : (
                  <>
                  <Button colorScheme='red' mr={3} onClick={onClose}>
                    ביטול
                  </Button>
                  <Button colorScheme='green' onClick={handleSubmit}>
                    הוספה
                  </Button>
                  </>
                )}
              </ModalFooter>
            </ModalContent>
        </Modal>
    </Box>
  )
}

export default AddProjectPaymentModal;
