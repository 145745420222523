import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, Flex, FormControl, FormLabel, IconButton, Input, Spinner, Table, Tbody, Td, Text, Thead, Tr, useColorMode, useToast } from '@chakra-ui/react'
import axios from 'axios';
import AddProductModal from '../../components/Modals/addProductModal';
import { useNavigate, useParams } from 'react-router-dom';
import { MdDeleteOutline } from 'react-icons/md';
import { useAuth } from '../../Auth/AuthContext';
import { url } from '../../assets/urlAdress';

type Product = { name: string, number: string, quantity: number, price: number, deduction: number };

function calculateOrderTotal(products:any) {
    let total = 0;
    for (const product of products) {
      const pricePerUnit = product.product.Price;
      const quantity = product.product.quantity;
      const deduction = (1 - (product.product.deduction/100));
      const productTotal = pricePerUnit * deduction * quantity;
      total += productTotal;
    }
    return total;
}

function CreateSupplierOrder() {
  const { setIsAuthenticated } = useAuth();
  const supplierId = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { colorMode  } = useColorMode();
  const headerColor = colorMode === "dark" ? "black" : "#EFEFEFF6";
  const [isLoading ,setIsLoading] = useState(true)
  
  const fetchData = async () => {
    try {
        const response1 = await axios.get(`${url}/Cars`, { withCredentials: true });
        const response2 = await axios.get(`${url}/Projects`, { withCredentials: true });
        setCars(response1.data);
        setWorkSite(response2.data);
        setIsLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  
  const [cars, setCars] = useState([]);
  const [workSites, setWorkSite] = useState([]);
  const [addProductModal, setAddProductModal] = useState(false)

  const [chosenProducts, setChosenProducts] = useState<any[]>([]);
  const [payed, setPayed] = useState(0);
  const [invoicingNum, setInvoicingNum] = useState('')
  const [isChecked, setIsChecked] = useState(false);


  const handleAddProduct = async (data:any) => {
    try {
      if (data.product && (data.supplier === null || data.worksite === null)) {
        setChosenProducts([...chosenProducts, data]);
      }
    } catch (error:any) {
      console.log('Error: ' + error)
      toast({
        title: 'חוסר/טעות בנתונים',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      if (error?.response?.status === 401) { setIsAuthenticated(false); }
    }
  }
  const removeProduct = (indexToRemove:number) => {
    setChosenProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts.splice(indexToRemove, 1);
      return updatedProducts;
    });
  };
  
  const [ifSubmitLoading, setIfSubmitLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIfSubmitLoading(true);
      const response = await axios.post(`${url}/Suppliers/${supplierId.id}/addOrder`, 
      { Payed: payed, products: chosenProducts, invoicingNum: invoicingNum, isChecked}, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'הוצאוצ הוספו בהצלחה',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      }
      navigate(`${url}/Suppliers/${supplierId.id}`)
    } catch (error:any) {
      console.log('Error: ' + error)
      toast({
        title: 'חוסר/טעות בנתונים',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    } finally { setIfSubmitLoading(false); }
  }

  return (
    <Box dir='rtl' margin={{ base: 0, md: 4 }}>
      {isLoading ? (
        <Box
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          padding="2rem"
          borderRadius="8px"
          zIndex="1000"
        >
           <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
        </Box>
      ) : (
        <>
      <AddProductModal title={'הוספת מוצר להזמנה'} isOpen={addProductModal} onClose={()=>{setAddProductModal(false)}} 
        yesFunction={handleAddProduct} showCar={true} showSupplier={false} showWorkSite={true} suppliers={[]} worksites={workSites} cars={cars} />
      <Text fontSize='3xl' textAlign='center' fontWeight="bold">הוספת הזמנה מספאק</Text><br/>
      <FormControl>
          <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
              מס" הזמנה   
          </FormLabel>
        <Input value={invoicingNum} onChange={(e)=>setInvoicingNum(e.target.value)} type='text' />
      </FormControl><br/>

      <FormControl>
          <Flex justifyContent='space-between'>
              <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מוצרים:</FormLabel>
              <Box>
                <Checkbox isChecked={isChecked} onChange={(e)=>setIsChecked(e.target.checked)}>עדכן רכבים ואתרי בניה</Checkbox>
                <Button textColor='white' bgColor='green' borderRadius='50' onClick={()=>setAddProductModal(true)}>הוספת מוצר</Button>
              </Box>
          </Flex><br/>
          <Table>
              <Thead sx={{bgColor: headerColor}}>
                  <Tr>
                      <Td fontWeight="bold" fontSize='large'>שם מוצר</Td>
                      <Td fontWeight="bold" fontSize='large'>מספר פריט</Td>
                      <Td fontWeight="bold" fontSize='large'>מחיר ליחיד</Td>
                      <Td fontWeight="bold" fontSize='large'>%</Td>
                      <Td fontWeight="bold" fontSize='large'>מחיר נטו</Td>
                      <Td fontWeight="bold" fontSize='large'>כמות</Td>
                      <Td fontWeight="bold" fontSize='large'>סב"כ בש"ח</Td>
                      <Td fontWeight="bold" fontSize='large'>הוסף לרכב</Td>
                      <Td fontWeight="bold" fontSize='large'>הוסף ל א.בניה</Td>
                      <Td fontWeight="bold" fontSize='large'>למחוק</Td>
                  </Tr>
              </Thead>
              <Tbody>
                  {chosenProducts.map((item:any, index:number) => (
                    <Tr key={index}>
                      <Td fontSize='large'>{item.product.name}</Td>
                      <Td fontSize='large'>{item.product.Code}</Td>
                      <Td fontSize='large'>{item.product.Price}</Td>
                      <Td fontSize='large'>{item.product.deduction}</Td>
                      <Td fontSize='large'>{Number(item.product.Price * (1 - (item.product.deduction/100)))}</Td>
                      <Td fontSize='large'>{item.product.quantity}</Td>
                      <Td fontSize='large'>{Number(item.product.Price * (1 - (item.product.deduction/100)) * item.product.quantity)}</Td>
                      <Td fontSize='large'>{item?.car?.name || 'אין'}</Td>
                      <Td fontSize='large'>{item?.workSite?.name || 'אין'}</Td>
                      <Td fontSize='large'><IconButton colorScheme='red' aria-label='Call Sage' fontSize='22px' icon={<MdDeleteOutline/>} onClick={() => removeProduct(index)}/></Td>
                    </Tr>
                  ))}
                  <Tr><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td>
                  <Td fontWeight="bold" fontSize='large'>סכ"ה בש"ח</Td><Td fontWeight="bold" fontSize='large'>{calculateOrderTotal(chosenProducts)}</Td></Tr>
                  <Tr><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td>
                  <Td fontWeight="bold" fontSize='large'>מע"ם</Td><Td fontWeight="bold" fontSize='large'>{Number(calculateOrderTotal(chosenProducts) *0.17).toFixed(2)}</Td></Tr>
                  <Tr><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td>
                  <Td  fontWeight="bold" fontSize='large'>לתשלום</Td><Td fontWeight="bold" fontSize='large'>{Number(calculateOrderTotal(chosenProducts) * 1.17).toFixed(2)}</Td></Tr>
              </Tbody>
          </Table>
      </FormControl><br/>

      <FormControl>
          <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
              שולם  
          </FormLabel>
        <Input value={payed} onChange={(e)=>setPayed(Number(e.target.value))} type='number' />
      </FormControl><br/>
      {ifSubmitLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
        <Button type='submit' sx={{
            boxShadow: '10px',
            backgroundColor: '#4caf50',
            border: 'none',
            color: 'white',
            textAlign: 'center',
            fontSize: '16px',
            borderRadius: '20px',
            width: '100%',
        }} onClick={handleSubmit}>הוספה
        </Button>
      )}
      </>)}
    </Box>
  )
}

export default CreateSupplierOrder;

