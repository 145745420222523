import { Box, Button, FormControl, FormLabel, Input, Stack, Text, useToast } from '@chakra-ui/react'
import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';

import { url } from '../../assets/urlAdress';

const CreateProject = () => {
    const { setIsAuthenticated } = useAuth();
    const navigate = useNavigate();
    const toast = useToast()

    const handleSubmit = async () => {
        try {
          const response = await axios.post(`${url}/Projects`, {
            name: name,
            location: location,
            assessment: assessment,
            contact: { 
                manger: { name: managerName, phoneNumber: managerPhone, },
                secretary: { name: secretaryName, phoneNumber: secretaryPhone, }
            },
            startDate: { day: startDateDay, month: startDateMonth, year: startDateYear },
            planEndDate: { day: planEndDateDay, month: planEndDateMonth, year: planEndDateYear},
          }, { withCredentials: true });
    
          if (response.status === 200) {
            toast({
              title: 'פרויקט הוסף',
              status: 'success',
              duration: 5000,
              position: 'top-right',
              isClosable: true,
            });
            navigate('/Projects');
          }
        } catch (error:any) {
          console.error('Error creating car:', error);
          toast({
            title: 'חוסר/טעות בנתונים',
            description: error?.response?.data?.message,
            status: 'error',
            position: 'top-right',
            duration: 5000,
            isClosable: true,
          });
          if (error?.response?.status === 401) { setIsAuthenticated(false); }
        }
    };
    
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [assessment, setAssessment] = useState('');
    const [managerName, setManagerName] = useState('');
    const [managerPhone, setManagerPhone] = useState('');
    const [secretaryName, setSecretaryName] = useState('');
    const [secretaryPhone, setSecretaryPhone] = useState('');
    const [startDateYear, setStartDateYear] = useState((new Date()).getFullYear());
    const [startDateMonth, setStartDateMonth] = useState((new Date()).getMonth() + 1);
    const [startDateDay, setStartDateDay] = useState((new Date()).getDate());
    const [planEndDateYear, setPlanEndDateYear] = useState((new Date()).getFullYear());
    const [planEndDateMonth, setPlanEndDateMonth] = useState((new Date()).getMonth() + 1);
    const [planEndDateDay, setPlanEndDateDay] = useState((new Date()).getDate());

    const handleProjectNameChange = (e:any) => {
        setName(e.target.value);
    };

    const handleProjectLocationChange = (e:any) => {
        setLocation(e.target.value);
    };

    const handleProjectAssessmentChange = (e:any) => {
        setAssessment(e.target.value);
    };

    const handleManagerNameChange = (e:any) => {
        setManagerName(e.target.value);
    };

    const handleManagerPhoneChange = (e:any) => {
        setManagerPhone(e.target.value);
    };

    const handleSecretaryNameChange = (e:any) => {
        setSecretaryName(e.target.value);
    };

    const handleSecretaryPhoneChange = (e:any) => {
        setSecretaryPhone(e.target.value);
    };

    const handleStartDateYearChange = (e:any) => {
        setStartDateYear(e.target.value);
    };

    const handleStartDateMonthChange = (e:any) => {
        setStartDateMonth(e.target.value);
    };

    const handleStartDateDayChange = (e:any) => {
        setStartDateDay(e.target.value);
    };

    const handlePlanEndDateYearChange = (e:any) => {
        setPlanEndDateYear(e.target.value);
    };

    const handlePlanEndDateMonthChange = (e:any) => {
        setPlanEndDateMonth(e.target.value);
    };

    const handlePlanEndDateDayChange = (e:any) => {
        setPlanEndDateDay(e.target.value);
    };

  return (
    <Box dir='rtl' margin={{ base: 0, md: 5 }}>
        <Text fontSize='3xl' textAlign='center' fontWeight="bold">הוספת פרויקט</Text><br/>

        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
              שם פרויקט
            </FormLabel>
          <Input value={name} type='text' onChange={handleProjectNameChange}/>
        </FormControl><br/>
        <FormControl>
          <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מיקום הפרויקט</FormLabel>
          <Input value={location} type='text' onChange={handleProjectLocationChange}/>
        </FormControl><br/>

        {/* Number */}
        <FormControl>
           <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
                הערכה
            </FormLabel>
           <Input value={assessment} type='number' onChange={handleProjectAssessmentChange}/>
        </FormControl><br/>

        {/* Contact */}
        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">איש קשר - מנהל</FormLabel>
            <Stack spacing={4} direction={{ base: 'column', md: 'row'}} >
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שם:</FormLabel>
                    <Input value={managerName} type='text' onChange={handleManagerNameChange}/>
                </Stack>
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מספר:</FormLabel>
                    <Input value={managerPhone} type='text' onChange={handleManagerPhoneChange}/>
                </Stack>
            </Stack>
        </FormControl><br/>

        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">איש קשר - שני</FormLabel>
            <Stack spacing={4} direction={{ base: 'column', md: 'row'}} >
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שם:</FormLabel>
                    <Input value={secretaryName} type='text' onChange={handleSecretaryNameChange}/>
                </Stack>
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מספר:</FormLabel>
                    <Input value={secretaryPhone} type='text' onChange={handleSecretaryPhoneChange}/>
                </Stack>
            </Stack>
        </FormControl><br/>

        {/* Dates */}
        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">תאריך תחילה</FormLabel>
            <Stack spacing={4} direction={{ base: 'column-reverse', md: 'row'}} >
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שנה:</FormLabel>
                    <Input value={startDateYear} type='number' onChange={handleStartDateYearChange}/>
                </Stack>
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חודש:</FormLabel>
                    <Input value={startDateMonth} type='number' onChange={handleStartDateMonthChange}/>
                </Stack>
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יום:</FormLabel>
                    <Input value={startDateDay} type='number' onChange={handleStartDateDayChange}/>
                </Stack>
            </Stack>
        </FormControl><br/>

        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">תאריך תכנון לסיום</FormLabel>
            <Stack spacing={4} direction={{ base: 'column-reverse', md: 'row'}} >
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שנה:</FormLabel>
                    <Input value={planEndDateYear} type='number' onChange={handlePlanEndDateYearChange}/>
                </Stack>
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חודש:</FormLabel>
                    <Input value={planEndDateMonth} type='number' onChange={handlePlanEndDateMonthChange}/>
                </Stack>
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יום:</FormLabel>
                    <Input value={planEndDateDay} type='number' onChange={handlePlanEndDateDayChange}/>
                </Stack>
            </Stack>
        </FormControl>
        <br/><br/>
        <Button type='submit' sx={{
            boxShadow: '10px',
            backgroundColor: '#4caf50',
            border: 'none',
            color: 'white',
            textAlign: 'center',
            fontSize: '16px',
            borderRadius: '20px',
            width: '100%',
        }} onClick={handleSubmit}>הוספה</Button>
    </Box>
  )
}

export default CreateProject;