import { Box, Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner, Textarea, useBreakpointValue, useToast } from '@chakra-ui/react'
import axios from 'axios';
import React, { useState } from 'react'
import { url } from '../../../assets/urlAdress';

interface AddProjectPaymentProps {
  isOpen: boolean;
  projectId: any;
  onClose: () => void;
  yesFunction: () => void;
}

function AddTaskModal({ isOpen, onClose, projectId, yesFunction}:AddProjectPaymentProps) {
    const toast = useToast();
    const modalSize = useBreakpointValue({ base: "full", md: "3xl" });

  const [taskDescription, setTaskDescription] = useState('');
  const [taskQuantity, setTaskQuantity] = useState('');
  const [taskUnit, setTaskUnit] = useState('מ"א');
  const [taskPrice, setTaskPrice] = useState('');

  const [isAddButtonLoading, setIsAddButtonLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsAddButtonLoading(true);
      const response = await axios.post(`${url}/Projects/${projectId.id}/Tasks/create`, {
        description: taskDescription,
        quantity: taskQuantity,
        unit: taskUnit,
        price: taskPrice,
      }, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'הוסף בהצלחה',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        yesFunction();
        onClose()
      }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'חוסר/טעות בנתונים',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    } finally { setIsAddButtonLoading(false); }
  }

  return (
    <Box>
        <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize={{base: 'medium', md: 'x-large'}} display="flex" justifyContent="center" alignItems="center" fontWeight="bold">הוספת משימה</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Box dir='rtl'>
                    <FormControl>
                        <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">פרטים</FormLabel>
                        <Textarea
                          placeholder="תיאור"
                          value={taskDescription}
                          onChange={(e) => setTaskDescription(e.target.value)}
                        />
                    </FormControl><br/>
                    <FormControl>
                        <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">כמות</FormLabel>
                        <Input
                          placeholder="כמות"
                          value={taskQuantity}
                          onChange={(e) => setTaskQuantity(e.target.value)}
                        />
                    </FormControl><br/>
                    <FormControl>
                        <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יחידה</FormLabel>
                        <Select
                          value={taskUnit} dir='ltr'
                          onChange={(e) => setTaskUnit(e.target.value)}
                        >
                          <option value={'מ"א'}>מ"א</option>
                          <option value={'מ"ר'}>מ"ר</option>
                          <option value={"'יח"}>'יח</option>
                          <option value={'ס"ה'}>ס"ה</option>
                        </Select>
                    </FormControl><br/>
                    <FormControl>
                        <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מחיר ליחידה</FormLabel>
                        <Input
                          placeholder="מחיר"
                          value={taskPrice}
                          onChange={(e) => setTaskPrice(e.target.value)}
                        />
                    </FormControl>
                </Box>              
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='red' mr={3} onClick={onClose}>
                ביטול
              </Button>
              {isAddButtonLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
              <Button colorScheme='green' onClick={handleSubmit}>הוספה</Button>)}
              </ModalFooter>
          </ModalContent>
        </Modal>
    </Box>
  )
}

export default AddTaskModal
