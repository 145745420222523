import { Box, Button, FormControl, FormLabel, Input, Select, Spinner, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';

import { url } from '../../assets/urlAdress';

function EditProject() {
    const { setIsAuthenticated } = useAuth();
    const projectId = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const toast = useToast()
    const [isAddButtonLoading, setIsAddButtonLoading] = useState(false);

    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/Projects/${projectId.id}`, { withCredentials: true });
        const data = response.data;
        setName(data.name);
        setLocation(data.location);
        setAssessment(data.assessment);
        setExpenses(data.expenses)
        setEarn(data.earn)
        setManagerName(data.contact.manger.name);
        setManagerPhone(data.contact.manger.phoneNumber);
        setSecretaryName(data.contact.secretary.name);
        setSecretaryPhone(data.contact.secretary.phoneNumber);
        setStartDateYear(data.startDate.year);
        setStartDateMonth(data.startDate.month);
        setStartDateDay(data.startDate.day);
        setPlanEndDateYear(data.planEndDate.year);
        setPlanEndDateMonth(data.planEndDate.month);
        setPlanEndDateDay(data.planEndDate.day);
        setStatus(data.status)
        setEndDateYear(data.endDate.year || '');
        setEndDateMonth(data.endDate.month || '');
        setEndDateDay(data.endDate.day || '');
        setIsLoading(false);
      } catch (error:any) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
        if (error?.response?.status === 401) { setIsAuthenticated(false); }
      }
    };

    useEffect(() => {
      fetchData();
    }, []);

    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [assessment, setAssessment] = useState(0);
    const [expenses, setExpenses] = useState(0);
    const [earn, setEarn] = useState(0);
    const [managerName, setManagerName] = useState('');
    const [managerPhone, setManagerPhone] = useState('');
    const [secretaryName, setSecretaryName] = useState('');
    const [secretaryPhone, setSecretaryPhone] = useState('');
    const [startDateYear, setStartDateYear] = useState((new Date()).getFullYear());
    const [startDateMonth, setStartDateMonth] = useState((new Date()).getMonth() + 1);
    const [startDateDay, setStartDateDay] = useState((new Date()).getDate());
    const [planEndDateYear, setPlanEndDateYear] = useState((new Date()).getFullYear());
    const [planEndDateMonth, setPlanEndDateMonth] = useState((new Date()).getMonth() + 1);
    const [planEndDateDay, setPlanEndDateDay] = useState((new Date()).getDate());
    const [endDateYear, setEndDateYear] = useState();
    const [endDateMonth, setEndDateMonth] = useState();
    const [endDateDay, setEndDateDay] = useState();
    const [status, setStatus] = useState('');

    const handleProjectNameChange = (e:any) => {
        setName(e.target.value);
    };

    const handleProjectLocationChange = (e:any) => {
        setLocation(e.target.value);
    };

    const handleProjectAssessmentChange = (e:any) => {
        setAssessment(e.target.value);
    };

    const handleManagerNameChange = (e:any) => {
        setManagerName(e.target.value);
    };

    const handleManagerPhoneChange = (e:any) => {
        setManagerPhone(e.target.value);
    };

    const handleSecretaryNameChange = (e:any) => {
        setSecretaryName(e.target.value);
    };

    const handleSecretaryPhoneChange = (e:any) => {
        setSecretaryPhone(e.target.value);
    };

    const handleStartDateYearChange = (e:any) => {
        setStartDateYear(e.target.value);
    };

    const handleStartDateMonthChange = (e:any) => {
        setStartDateMonth(e.target.value);
    };

    const handleStartDateDayChange = (e:any) => {
        setStartDateDay(e.target.value);
    };

    const handlePlanEndDateYearChange = (e:any) => {
        setPlanEndDateYear(e.target.value);
    };

    const handlePlanEndDateMonthChange = (e:any) => {
        setPlanEndDateMonth(e.target.value);
    };

    const handlePlanEndDateDayChange = (e:any) => {
        setPlanEndDateDay(e.target.value);
    };

    const handleEndDateYearChange = (e:any) => {
        setEndDateYear(e.target.value);
    };

    const handleEndDateMonthChange = (e:any) => {
        setEndDateMonth(e.target.value);
    };

    const handleEndDateDayChange = (e:any) => {
        setEndDateDay(e.target.value);
    };

    const handleSubmit = async () => {
        try {
          setIsAddButtonLoading(true);
          const response = await axios.patch(`${url}/Projects/${projectId.id}`, {    
            name: name,
            location: location,
            assessment: assessment,
            status: status,
            expenses: expenses,
            earn: earn,
            contact: { 
                manger: { name: managerName, phoneNumber: managerPhone, },
                secretary: { name: secretaryName, phoneNumber: secretaryPhone, }
            },
            startDate: { day: startDateDay, month: startDateMonth, year: startDateYear },
            planEndDate: { day: planEndDateDay, month: planEndDateMonth, year: planEndDateYear},
            endDate: { day: endDateDay, month: endDateMonth, year: endDateYear},
          }, { withCredentials: true });
    
          if (response.status === 200) {
            toast({
              title: 'פרויקט עודכן',
              status: 'success',
              duration: 5000,
              position: 'top-right',
              isClosable: true,
            });
            navigate(`/Projects/${projectId.id}`);
          }
        } catch (error:any) {
          console.error('Error creating car:', error);
          toast({
            title: 'חוסר/טעות בנתונים',
            description: error?.response?.data?.message,
            status: 'error',
            position: 'top-right',
            duration: 5000,
            isClosable: true,
          });
        } finally { setIsAddButtonLoading(false) }
    };

  return (
    <Box dir='rtl' margin={{ base: 0, md: 5 }}>
              {isLoading ? (
      <Box
        position="fixed"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        padding="2rem"
        borderRadius="8px"
        zIndex="1000">
        <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
      </Box>
        ) : (
            <>
        <Text fontSize='3xl' textAlign='center' fontWeight="bold">הוספת פרויקט</Text><br/>

        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
              שם פרויקט
            </FormLabel>
          <Input value={name} type='text' onChange={handleProjectNameChange}/>
        </FormControl><br/>
        <FormControl>
          <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מיקום הפרויקט</FormLabel>
          <Input value={location} type='text' onChange={handleProjectLocationChange}/>
        </FormControl><br/>

        {/* Number */}
        <FormControl>
           <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
                הערכה
            </FormLabel>
           <Input value={assessment} type='number' onChange={handleProjectAssessmentChange}/>
        </FormControl><br/>

        <FormControl>
           <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
            הוצאות
            </FormLabel>
           <Input value={expenses} type='number' onChange={(e)=> setExpenses(Number(e.target.value))}/>
        </FormControl><br/>

        <FormControl>
           <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
            הכנסות  
            </FormLabel>
           <Input value={earn} type='number' onChange={(e)=>setEarn(Number(e.target.value))}/>
        </FormControl><br/>

        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מצב עובד</FormLabel>
            <Select dir='ltr' placeholder='מצב הפרויקט' value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value='In Progress'>בתהליך</option>
              <option value='Completed'>הסתיים</option>
              <option value='On Hold'>בהשהייה</option>
              <option value='Canceled'>נבטל</option>
            </Select>
        </FormControl><br/>

        {/* Contact */}
        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">איש קשר - מנהל</FormLabel>
            <Stack spacing={4} direction={{ base: 'column', md: 'row'}} >
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שם:</FormLabel>
                    <Input value={managerName} type='text' onChange={handleManagerNameChange}/>
                </Stack>
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מספר:</FormLabel>
                    <Input value={managerPhone} type='text' onChange={handleManagerPhoneChange}/>
                </Stack>
            </Stack>
        </FormControl><br/>

        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">איש קשר - שני</FormLabel>
            <Stack spacing={4} direction={{ base: 'column', md: 'row'}} >
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שם:</FormLabel>
                    <Input value={secretaryName} type='text' onChange={handleSecretaryNameChange}/>
                </Stack>
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מספר:</FormLabel>
                    <Input value={secretaryPhone} type='text' onChange={handleSecretaryPhoneChange}/>
                </Stack>
            </Stack>
        </FormControl><br/>

        {/* Dates */}
        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">תאריך תחילה</FormLabel>
            <Stack spacing={4} direction={{ base: 'column-reverse', md: 'row'}} >
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שנה:</FormLabel>
                    <Input value={startDateYear} type='number' onChange={handleStartDateYearChange}/>
                </Stack>
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חודש:</FormLabel>
                    <Input value={startDateMonth} type='number' onChange={handleStartDateMonthChange}/>
                </Stack>
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יום:</FormLabel>
                    <Input value={startDateDay} type='number' onChange={handleStartDateDayChange}/>
                </Stack>
            </Stack>
        </FormControl><br/>

        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">תאריך תכנון לסיום</FormLabel>
            <Stack spacing={4} direction={{ base: 'column-reverse', md: 'row'}} >
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שנה:</FormLabel>
                    <Input value={planEndDateYear} type='number' onChange={handlePlanEndDateYearChange}/>
                </Stack>
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חודש:</FormLabel>
                    <Input value={planEndDateMonth} type='number' onChange={handlePlanEndDateMonthChange}/>
                </Stack>
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יום:</FormLabel>
                    <Input value={planEndDateDay} type='number' onChange={handlePlanEndDateDayChange}/>
                </Stack>
            </Stack>
        </FormControl><br/>

        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">תאריך סיום</FormLabel>
            <Stack spacing={4} direction={{ base: 'column-reverse', md: 'row'}} >
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שנה:</FormLabel>
                    <Input value={endDateYear} type='number' onChange={handleEndDateYearChange}/>
                </Stack>
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חודש:</FormLabel>
                    <Input value={endDateMonth} type='number' onChange={handleEndDateMonthChange}/>
                </Stack>
                <Stack direction='row'>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יום:</FormLabel>
                    <Input value={endDateDay} type='number' onChange={handleEndDateDayChange}/>
                </Stack>
            </Stack>
        </FormControl><br/>

        <br/><br/>
        {isAddButtonLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
            <Button type='submit' sx={{
                boxShadow: '10px',
                backgroundColor: '#4caf50',
                border: 'none',
                color: 'white',
                textAlign: 'center',
                fontSize: '16px',
                borderRadius: '20px',
                width: '100%',
            }} onClick={handleSubmit}>עריכה</Button>
        )}
        </>)}
    </Box>
  )
}

export default EditProject
