import { useColorMode, Switch, Icon, Flex } from "@chakra-ui/react";
import { FaSun, FaMoon } from "react-icons/fa";

function ColorModeToggle() {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex alignItems="center">
      <Switch
        colorScheme="teal" // You can choose your desired color scheme
        isChecked={colorMode === "dark"}
        onChange={toggleColorMode}
      />
      {colorMode === "dark" ? (
        <Icon as={FaMoon} color="gray.500" ml="2" />
      ) : (
        <Icon as={FaSun} color="yellow.500" ml="2" />
      )}    </Flex>
  );
}

export default ColorModeToggle;