import {
  Box,
  Drawer,
  DrawerContent,
  useDisclosure,
} from '@chakra-ui/react'
import { FiHome, FiLogOut, } from 'react-icons/fi'
import { BsFillPersonLinesFill } from 'react-icons/bs'
import { IconType } from 'react-icons'
import MobileNav from '../../SideBarComponents/MobileNav'
import SidebarContent from '../../SideBarComponents/SidebarContent'
import { FaCar, FaMapMarkerAlt, FaWallet, FaBuilding   } from 'react-icons/fa'

interface LinkItemProps {
  name: string;
  icon: IconType;
  to: string;
}
const LinkItems: Array<LinkItemProps> = [
  // { name: 'בית', icon: FiHome,                    to: '' },
  { name: 'פרוקטים', icon: FaMapMarkerAlt,        to: 'Projects' },
  { name: 'עובדים',  icon: BsFillPersonLinesFill, to: 'Workers' },
  { name: 'רכבים',   icon: FaCar,                 to: 'Cars' },
  { name: 'ספקים',   icon: FaBuilding,            to: 'Suppliers'},
  { name: 'צ"יקים',  icon: FaWallet,              to: 'Checks'},
]

export default function Sidebar() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box >
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} LinkItems={LinkItems} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} LinkItems={LinkItems} />
        </DrawerContent>
      </Drawer>
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
    </Box>
  )
}