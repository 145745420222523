import {
  Box,
  Flex,
  Icon,
  FlexProps,
} from '@chakra-ui/react'

import { IconType } from 'react-icons'
import { ReactText } from 'react'
  
interface NavItemProps extends FlexProps {
    icon: IconType;
    children: ReactText;
    onClose: () => void;
}

function NavItem({ icon, children, onClose, ...rest } : NavItemProps) {
  const handleClick = () => {
    onClose(); // Call the onClose function when a NavItem is clicked
  };
  return (
    <Box
      as="a"
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      onClick={handleClick}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
}

export default NavItem;