import React, { useState } from 'react'
import { Box, Button, FormControl, FormLabel, Input, Spinner, Text, useToast } from '@chakra-ui/react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';

import { url } from '../../assets/urlAdress';

function CreateCar() {
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const toast = useToast()

  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [currHolder, setCurrHolder] = useState('')

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true); 
      const response = await axios.post(`${url}/cars`, {name, number, currHolder, }, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'רכב הוסף',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        navigate('/cars');
      }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'חוסר/טעות בנתונים',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
      if (error?.response?.status === 401) { setIsAuthenticated(false); }
    } finally { setIsLoading(false); }
  };

  return (
    <Box dir='rtl' margin={{ base: 0, md: 5 }}>
      <Text fontSize='3xl' textAlign='center' fontWeight="bold">הוספת רכב</Text><br/>
        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold" >
              שם רכב
            </FormLabel>
          <Input value={name} name='name' type='text' onChange={(e:any) => setName(e.target.value)}/>
        </FormControl><br/>
        <FormControl>
          <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מספר רכב</FormLabel>
          <Input value={number} name='text' type='text' onChange={(e:any) => setNumber(e.target.value)}/>
        </FormControl><br/>

        <FormControl>
          <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מחזיק נוכחי</FormLabel>
          <Input value={currHolder} name='text' type='text' onChange={(e:any) => setCurrHolder(e.target.value)}/>
        </FormControl>

        <br/><br/>
        {isLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
          <Button type='submit' sx={{
              boxShadow: '10px',
              backgroundColor: '#4caf50',
              border: 'none',
              color: 'white',
              textAlign: 'center',
              fontSize: '16px',
              borderRadius: '20px',
              width: '100%',
          }} onClick={handleSubmit}>הוספה
          </Button>
        )}
    </Box>
  )
}

export default CreateCar