import React, { useState } from 'react'
import { Box, Button, FormControl, FormLabel, Input, Spinner, Text, useToast } from '@chakra-ui/react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';

import { url } from '../../assets/urlAdress';

function CreateSupplier() {
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const toast = useToast()

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${url}/Suppliers`, { name, phoneNumber, companyNumber, }, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'ספק הוסף',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        navigate('/Suppliers');
      }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'חוסר/טעות בנתונים',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
      if (error?.response?.status === 401) { setIsAuthenticated(false); }
    } finally { setIsLoading(false); }
  }

  return (
    <Box dir='rtl' margin={{ base: 0, md: 5 }}>
    <Text fontSize='3xl' textAlign='center' fontWeight="bold">הוספת ספאק</Text><br/>

        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
              שם ספאק
            </FormLabel>
          <Input value={name} type='text' onChange={(e)=>setName(e.target.value)} />
        </FormControl><br/>
        <FormControl>
          <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מספר חברה</FormLabel>
          <Input value={companyNumber} type='text' onChange={(e)=>setCompanyNumber(e.target.value)} />
        </FormControl><br/>

        <FormControl>
          <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מספר נייד</FormLabel>
          <Input value={phoneNumber} type='text' onChange={(e)=>setPhoneNumber(e.target.value)} />
        </FormControl>

        <br/><br/>
        {isLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
          <Button type='submit' sx={{
              boxShadow: '10px',
              backgroundColor: '#4caf50',
              border: 'none',
              color: 'white',
              textAlign: 'center',
              fontSize: '16px',
              borderRadius: '20px',
              width: '100%',
          }} onClick={handleSubmit}>הוספה
          </Button>
        )}

    </Box>
  )
}

export default CreateSupplier;
