import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, FormControl, FormLabel, Stack, Input, Select, useBreakpointValue, useToast, Spinner } from '@chakra-ui/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { url } from '../../../assets/urlAdress';

interface AddCarUsageProps {
  carName: string;
  isOpen: boolean;
  onClose: () => void;
  carId: string;
  currentDriver: string;
}

function AddCarUsage({ carName, isOpen, onClose, carId, currentDriver }:AddCarUsageProps) {
  const modalSize = useBreakpointValue({ base: "full", md: "3xl" });
  const [workSitesData, setWorkSitesData] = useState([]);
  const toast = useToast()

  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}/Projects`, { withCredentials: true });
      if (response.status === 200) {
        const data = response.data || [];
        setWorkSitesData(data)
      }
      setCurrDriver(currentDriver);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };
  
  // Call the fetchData function when your component mounts or when needed
  useEffect(() => {
    fetchData();
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const [day, setDay] = useState((new Date()).getDate());
  const [month, setMonth] = useState((new Date()).getMonth() + 1);
  const [year, setYear] = useState((new Date()).getFullYear());

  const [currDriver, setCurrDriver] = useState(currentDriver);
  const [workSite, setWorkSite] = useState('');

  const [fuelExpense, setFuelExpense] = useState(0);

  const yesHandleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${url}/Cars/${carId}/usage/create`, {day, month, year, workSite, driver: currDriver, fuelConsumption: fuelExpense}, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'שימוש רכב הוסף',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        onClose();
      }
    } catch (error:any) {
      console.log("error: " + error)
      toast({
        title: 'חוסר/טעות בנתונים',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    } finally { setIsLoading(false); }
  }

  return (
    <Box dir='rtl'>
        <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontSize={{base: 'medium', md: 'x-large'}} display="flex" justifyContent="center" alignItems="center" fontWeight="bold">הוספת שימוש לרכב {carName}</ModalHeader>
              <ModalCloseButton />
              <ModalBody dir='rtl'>
                <FormControl>
                     <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">תאריך שימוש</FormLabel>
                     <Stack spacing={4} direction={{ base: 'column-reverse', md: 'row'}} >
                         <Stack direction='row'>
                             <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שנה:</FormLabel>
                             <Input value={year} type='number' onChange={(e) => setYear(Number(e.target.value))} />
                         </Stack>
                         <Stack direction='row'>
                             <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חודש:</FormLabel>
                             <Input value={month} type='number' onChange={(e) => setMonth(Number(e.target.value))} />
                         </Stack>
                         <Stack direction='row'>
                             <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יום:</FormLabel>
                             <Input value={day} type='number' onChange={(e) => setDay(Number(e.target.value))} />
                         </Stack>
                     </Stack>
                 </FormControl><br/>

                <FormControl>
                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">נהג</FormLabel>
                  <Input value={currDriver} type='text' onChange={(e) => setCurrDriver(e.target.value)} />
                </FormControl><br/>

                <FormControl>
                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">אתר בניה</FormLabel>
                  <Select dir='ltr' placeholder="תבחור א.בניה" size='lg' value={workSite} onChange={(e) =>setWorkSite(e.target.value)}>
                    {workSitesData.map((project:any) => (
                      <option key={project.id} value={project._id}>{project.name}</option>
                    ))}
                  </Select>
                </FormControl><br/>

                <FormControl>
                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">צריכת דלק</FormLabel>
                  <Input name='text' type='Number' value={fuelExpense} onChange={(e)=>setFuelExpense(Number(e.target.value))}/>
                </FormControl><br/>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme='red' mr={3} onClick={onClose}>
                  ביטול
                </Button>
                {isLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
                <Button colorScheme='green' onClick={yesHandleSubmit}>הוספה</Button>)}
              </ModalFooter>
            </ModalContent>
        </Modal>
    </Box>
  )
}

export default AddCarUsage
