import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, FormControl, FormLabel, Stack, Input, Select, useBreakpointValue, color, Spinner } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

interface AddProductModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  yesFunction: (data: any) => void;
  showCar: Boolean;
  showSupplier: Boolean;
  showWorkSite: Boolean;
  suppliers: any;
  worksites: any;
  cars: any;
}


function AddProductModal({
  title,
  isOpen,
  onClose,
  yesFunction,
  showCar,
  showSupplier,
  showWorkSite,
  suppliers, 
  worksites, 
  cars,
}: AddProductModalProps) {
  const modalSize = useBreakpointValue({ base: "full", md: "3xl" });

  useEffect(() => {
      if (isOpen) {
        // Reset state values when the modal is opened
        setDay(new Date().getDate());
        setMonth(new Date().getMonth() + 1);
        setYear(new Date().getFullYear());
        setName('');
        setCode('');
        setPrice(0);
        setDeduction(0);
        setQuantity(0);
        setSupplier(null);
        setWorksite(null);
        setCar(null);
      }
  }, [isOpen]);

  // Create state variables for each input field
  const [day, setDay] = useState((new Date()).getDate());
  const [month, setMonth] = useState((new Date()).getMonth() + 1);
  const [year, setYear] = useState((new Date()).getFullYear());
  const [name, setName] = useState('');
  const [Code, setCode] = useState('');
  const [Price, setPrice] = useState(0);
  const [deduction, setDeduction] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [supplier, setSupplier] = useState<any>(null);
  const [worksite, setWorksite] = useState<any>(null);
  const [car, setCar] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box>
      <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={{base: 'medium', md: 'x-large'}} display="flex" justifyContent="center" alignItems="center" fontWeight="bold">{title}</ModalHeader>
          <ModalCloseButton />
            <ModalBody dir='rtl'>
                <FormControl>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">תאריך</FormLabel>
                    <Stack spacing={4} direction={{ base: 'column-reverse', md: 'row'}} >
                        <Stack direction='row'>
                            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שנה:</FormLabel>
                            <Input value={year} type='number' onChange={(e) => setYear(Number(e.target.value))}/>
                        </Stack>
                        <Stack direction='row'>
                            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חודש:</FormLabel>
                            <Input value={month} type='number' onChange={(e) => setMonth(Number(e.target.value))}/>
                        </Stack>
                        <Stack direction='row'>
                            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יום:</FormLabel>
                            <Input value={day} type='number' onChange={(e) => setDay(Number(e.target.value))}/>
                        </Stack>
                    </Stack>
                </FormControl><br/>

                <FormControl>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
                        שם מוצר	
                    </FormLabel>
                  <Input isRequired name='name' type='text' onChange={(e) => setName(e.target.value)}/>
                </FormControl><br/>

                <FormControl>
                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מספר פריט</FormLabel>
                  <Input name='text' type='text' onChange={(e) => setCode(e.target.value)}/>
                </FormControl><br/>

                <FormControl>
                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מחיר ליחיד</FormLabel>
                  <Input name='number' type='number' onChange={(e) => setPrice(Number(e.target.value))}/>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">הנחה</FormLabel>
                  <Input name='number' min={0} max={100} type='number' onChange={(e) => setDeduction(Number(e.target.value))}/>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">כמות</FormLabel>
                  <Input name='text' type='number' onChange={(e) => setQuantity(Number(e.target.value))}/>
                </FormControl>

              {showSupplier && (
                <FormControl>
                  <FormLabel
                    fontSize={{ base: 'medium', md: 'x-large' }}
                    fontWeight="bold"
                  >
                    בחר ספק
                  </FormLabel>
                  <Select
                    dir="ltr"
                    placeholder="תבחר ספק"
                    size="lg"
                    value={supplier ? supplier.id : ''}
                    onChange={(e) => {
                      const selectedSupplier = suppliers.find((supplier:any) => supplier._id === e.target.value);
                      setSupplier(selectedSupplier);
                    }}
                  >
                    {suppliers?.map((supplier:any, index:number) => (
                      <option key={index} value={supplier._id}>
                        {supplier.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}

              {showWorkSite && ( 
                <FormControl>
                  <FormLabel
                    fontSize={{ base: 'medium', md: 'x-large' }}
                    fontWeight="bold"
                  >
                    בחר אתר עבודה
                  </FormLabel>
                  <Select
                    dir="ltr"
                    placeholder="תבחר אתר עבודה"
                    size="lg"
                    value={worksite ? worksite.id : ''}
                    onChange={(e) => {
                      const selectedWorksite = worksites.find((worksite:any) => worksite._id === e.target.value);
                      setWorksite(selectedWorksite);
                    }}
                  >
                    {worksites.map((worksite:any, index:number) => (
                      <option key={index} value={worksite._id}>
                        {worksite.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}

              {showCar && (
                <FormControl>
                  <FormLabel
                    fontSize={{ base: 'medium', md: 'x-large' }}
                    fontWeight="bold">
                    או בחר רכב
                  </FormLabel>
                  <Select
                    dir='ltr'
                    placeholder="תבחר רכב"
                    size="lg"
                    value={car ? car.id : ''}
                    onChange={(e) => {
                      const selectedCar = cars.find((car:any) => car._id === e.target.value);
                      setCar(selectedCar); 
                    }}>
                    {cars.map((car:any, index:number) => (
                      <option key={index} value={car._id}>
                        {car.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={onClose}>
              ביטול
            </Button>
            {isLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
              <Button colorScheme='green' onClick={async () => {
                setIsLoading(true)
                await yesFunction({
                  date: {day: day, month: month, year: year,},
                  product: { name, Code, Price, deduction, quantity, },
                  supplier: supplier,
                  workSite: worksite,
                  car: car,
                }) 
                setIsLoading(false);
                onClose();
              }}>הוספה</Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default AddProductModal;