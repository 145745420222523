import { Box, Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner, Stack, Textarea, useBreakpointValue, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'

interface DateToDateModalProps {
  title: String;
  isOpen: boolean;
  onClose: () => void;
  yesFunction: (fromDate: any, toDate: any) => void;
}

function DateToDateModal({title, isOpen, onClose, yesFunction}:DateToDateModalProps) {
  const modalSize = useBreakpointValue({ base: "full", md: "3xl" });

  const [fromDay, setFromDay] = useState();
  const [fromMonth, setFromMonth] = useState();
  const [fromYear, setFromYear] = useState();

  const [toDay, setToDay] = useState();
  const [toMonth, setToMonth] = useState();
  const [toYear, setToYear] = useState();

  const [isAddButtonLoading, setIsAddButtonLoading] = useState(false);

  const handleSubmit = async () => {
    setIsAddButtonLoading(true);
    await yesFunction({day: fromDay, month: fromMonth, year: fromYear},{day: toDay, month: toMonth, year: toYear});
    setIsAddButtonLoading(false);
    onClose()
  }

  return (
    <Box>
        <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize={{base: 'medium', md: 'x-large'}} display="flex" justifyContent="center" alignItems="center" fontWeight="bold">{title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Box dir='rtl'>
                    <FormControl>
                      <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מתאריך</FormLabel>
                      <Stack spacing={4} direction={{ base: 'column-reverse', md: 'row'}} >
                          <Stack direction='row'>
                              <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שנה:</FormLabel>
                              <Input value={fromYear} type='number' onChange={(e:any)=>setFromYear(e.target.value)}/>
                          </Stack>
                          <Stack direction='row'>
                              <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חודש:</FormLabel>
                              <Input value={fromMonth} type='number' onChange={(e:any)=>setFromMonth(e.target.value)}/>
                          </Stack>
                          <Stack direction='row'>
                              <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יום:</FormLabel>
                              <Input value={fromDay} type='number' onChange={(e:any)=>setFromDay(e.target.value)}/>
                          </Stack>
                      </Stack>
                    </FormControl><br/>

                    <FormControl>
                      <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">לתאריך</FormLabel>
                      <Stack spacing={4} direction={{ base: 'column-reverse', md: 'row'}} >
                          <Stack direction='row'>
                              <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שנה:</FormLabel>
                              <Input value={toYear} type='number' onChange={(e:any)=>setToYear(e.target.value)}/>
                          </Stack>
                          <Stack direction='row'>
                              <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חודש:</FormLabel>
                              <Input value={toMonth} type='number' onChange={(e:any)=>setToMonth(e.target.value)}/>
                          </Stack>
                          <Stack direction='row'>
                              <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יום:</FormLabel>
                              <Input value={toDay} type='number' onChange={(e:any)=>setToDay(e.target.value)}/>
                          </Stack>
                      </Stack>
                    </FormControl><br/>
                </Box>              
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='red' mr={3} onClick={onClose}>
                ביטול
              </Button>
              {isAddButtonLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
              <Button colorScheme='green' onClick={handleSubmit}>אישור</Button>)}
              </ModalFooter>
          </ModalContent>
        </Modal>
    </Box>
  )
}

export default DateToDateModal
