import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, FormLabel, Input, Spinner, Text, useToast } from '@chakra-ui/react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';

import { url } from '../../assets/urlAdress';

function EditSupplier() {
  const { setIsAuthenticated } = useAuth();
  const supplierId = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}/Suppliers/${supplierId.id}`, { withCredentials: true });
      if (response.status === 200) {
        const data = response.data;
        setName(data.name)
        setPhoneNumber(data.phoneNumber)
        setCompanyNumber(data.companyNumber)
        setDept(data.dept)
        setIsLoading(false);
      }
    } catch (error:any) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      if (error?.response?.status === 401) { setIsAuthenticated(false); }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');
  const [dept, setDept] = useState(0);

  const [ifSubmmitLoading, setIfSubmmitLoading] = useState(false);
  
  const handleSubmit = async () => {
    try {
       setIfSubmmitLoading(true);
      const response = await axios.patch(`${url}/Suppliers/${supplierId.id}`, { name, phoneNumber, companyNumber, dept }, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'ספק עודכן',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        navigate(`/Suppliers/${supplierId.id}`);
      }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'חוסר/טעות בנתונים',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    } finally{ setIfSubmmitLoading(false); }
  }

  return (
    <Box dir='rtl' margin={{ base: 0, md: 5 }}>
    <Text fontSize='3xl' textAlign='center' fontWeight="bold">עריכת ספאק</Text><br/>
        {isLoading ? (
            <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                padding="2rem"
                borderRadius="8px"
                zIndex="1000"
            >
               <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
            </Box>
        ) : (
        <>
        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
              שם ספאק
            </FormLabel>
          <Input value={name} type='text' onChange={(e)=>setName(e.target.value)} />
        </FormControl><br/>
        <FormControl>
          <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מספר חברה</FormLabel>
          <Input value={companyNumber} type='text' onChange={(e)=>setCompanyNumber(e.target.value)} />
        </FormControl><br/>

        <FormControl>
          <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מספר נייד</FormLabel>
          <Input value={phoneNumber} type='text' onChange={(e)=>setPhoneNumber(e.target.value)} />
        </FormControl>

        <FormControl>
          <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חוב</FormLabel>
          <Input value={dept} type='number' onChange={(e)=>setDept(Number(e.target.value))} />
        </FormControl>

        <br/><br/>
        {ifSubmmitLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
          <Button type='submit' sx={{
              boxShadow: '10px',
              backgroundColor: '#4caf50',
              border: 'none',
              color: 'white',
              textAlign: 'center',
              fontSize: '16px',
              borderRadius: '20px',
              width: '100%',
          }} onClick={handleSubmit}>עריכה
          </Button>
        )}
        </>)}
    </Box>
  )
}

export default EditSupplier;
