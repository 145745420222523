import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, FormControl, FormLabel, Stack, Input, Select, useBreakpointValue, useToast, Spinner } from '@chakra-ui/react'
import axios from 'axios';
import React, { useState } from 'react'
import { url } from '../../../assets/urlAdress';

interface AddCarUsageProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  workerId: String;
  yesFunction: () => void;
}

function AddRestDayModal({ title, isOpen, onClose, workerId, yesFunction }:AddCarUsageProps) {
    const modalSize = useBreakpointValue({ base: "full", md: "3xl" });
    const toast = useToast()

    const [day, setDay] = useState((new Date()).getDate());
    const [month, setMonth] = useState((new Date()).getMonth() + 1);
    const [year, setYear] = useState((new Date()).getFullYear());

    const [restType, setRestType] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(`${url}/Workers/${workerId}/daysOff/createDayOff`, 
        {date: {day: day, month: month, year: year }, status: restType}, 
        { withCredentials: true });
        if (response.status === 200) {
          toast({
            title: 'יום חופש הוסף בהצלחה',
            status: 'success',
            duration: 5000,
            position: 'top-right',
            isClosable: true,
          });
          yesFunction();
          onClose();
        }
      } catch (error:any) {
        console.error('Error fetching data:', error);
        toast({
          title: 'חוסר/טעות בנתונים',
          description: error?.response?.data?.message,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      } finally { setIsLoading(false); }
    }

  return (
    <Box  dir='rtl'>
        <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontSize={{base: 'medium', md: 'x-large'}} display="flex" justifyContent="center" alignItems="center" fontWeight="bold">{title}</ModalHeader>
              <ModalCloseButton />
                <ModalBody dir='rtl'>
                    <FormControl>
                       <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">תאריך יום החופש</FormLabel>
                       <Stack spacing={4} direction={{ base: 'column-reverse', md: 'row'}} >
                           <Stack direction='row'>
                               <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שנה:</FormLabel>
                               <Input value={year} type='number' onChange={(e) => setYear(Number(e.target.value))} />
                           </Stack>
                           <Stack direction='row'>
                               <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חודש:</FormLabel>
                               <Input value={month} type='number' onChange={(e) => setMonth(Number(e.target.value))} />
                           </Stack>
                           <Stack direction='row'>
                               <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יום:</FormLabel>
                               <Input value={day} type='number' onChange={(e) => setDay(Number(e.target.value))} />
                           </Stack>
                       </Stack>
                    </FormControl><br/>

                    <FormControl>
                        <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">סוג החופש</FormLabel>
                        <Select value={restType} onChange={(e) => setRestType(e.target.value)} dir='ltr' placeholder="תבחור סוג החופש" size='lg' >
                          <option value='vacation'>חופשה</option>
                          <option value='sickLeave'>מחלה</option>
                        </Select>
                    </FormControl><br/>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme='red' mr={3} onClick={onClose}>
                    ביטול
                  </Button>
                  {isLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
                  <Button colorScheme='green' onClick={handleSubmit}>הוספה</Button>)}
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Box>
  )
}

export default AddRestDayModal
