import {
    IconButton,
    Flex,
    useColorModeValue,
    FlexProps,
} from '@chakra-ui/react'
import { FiMenu, } from 'react-icons/fi'
import ColorModeToggle from '../Toggles/ColorModeToggle'

interface MobileProps extends FlexProps {
    onOpen: () => void
}

function MobileNav({ onOpen, ...rest } : MobileProps) {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="space-between"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <img src="https://cdn.discordapp.com/attachments/1091485120151699497/1112482080325120121/image.png"
        alt="logoSmall" width="140px" />
             
      <ColorModeToggle/>
    </Flex>
  );
}

export default MobileNav;
