import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, FormLabel, Input, Select, Spinner, Text, useToast } from '@chakra-ui/react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';

import { url } from '../../assets/urlAdress';

function EditWorker() {
  const { setIsAuthenticated } = useAuth();
  const workerId = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [workSites, setWorkSites] = useState<any[]>();

  const fetchData = async () => {
        try {
          const response = await axios.get(`${url}/Workers/${workerId.id}`, { withCredentials: true });
          if (response.status === 200) {
            const data = response.data;
            setName(data.name)
            setPhoneNumber(data.phoneNumber)
            setDailyPayment(data.dailyPayment)
            setStatus(data.status)
            setCurrentWorkSite(data.currentWorkSite)
            setCurrIncome(data.currIncome)
          }
          const workSiteResponse = await axios.get(`${url}/Projects`, { withCredentials: true });
          if (workSiteResponse.status === 200) {
            setWorkSites(workSiteResponse.data);
          }
          setIsLoading(false)
        } catch (error:any) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
          if (error?.response?.status === 401) { setIsAuthenticated(false); }
        }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [currIncome, setCurrIncome] = useState(0);
  const [dailyPayment, setDailyPayment] = useState(0);
  const [status, setStatus] = useState('');
  const [currentWorkSite, setCurrentWorkSite] = useState<any>();

  const [isAddButtonLoading, setIsAddButtonLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsAddButtonLoading(true);
      const response = await axios.patch(`${url}/Workers/${workerId.id}`,  
      {name, phoneNumber, dailyPayment, status, currentWorkSite, currIncome, }, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'עובד עודכן',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        navigate(`/Workers/${workerId.id}`);
      }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'Error',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    } finally { setIsAddButtonLoading(false) }
  }

  return (
    <Box dir='rtl' margin={{ base: 0, md: 5 }}>
        {isLoading ? (
            <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                padding="2rem"
                borderRadius="8px"
                zIndex="1000"
            >
               <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
            </Box>
        ) : ( 
        <>
        <Text fontSize='3xl' textAlign='center' fontWeight="bold">עריכת עובד</Text><br/>
        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
              שם עובד
            </FormLabel>
          <Input value={name} type='text' onChange={(e) => setName(e.target.value)}/>
        </FormControl><br/>
        <FormControl>
          <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מספר נייד</FormLabel>
          <Input value={phoneNumber} type='text' onChange={(e) => setPhoneNumber(e.target.value)}/>
        </FormControl><br/>
        <FormControl>
           <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
                תשלום יומי
            </FormLabel>
           <Input value={dailyPayment} type='number' onChange={(e) => setDailyPayment(Number(e.target.value))}/>
        </FormControl><br/>

        <FormControl>
           <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
                נשאר לשלם 
            </FormLabel>
           <Input value={currIncome} type='number' onChange={(e) => setCurrIncome(Number(e.target.value))}/>
        </FormControl><br/>

        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מצב עובד</FormLabel>
            <Select dir='ltr' placeholder='Select option' value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value='ableToWork'>זמין</option>
              <option value='vacation'>חופשה</option>
              <option value='sickLeave'>מחלה</option>
            </Select>
        </FormControl><br/>

        <FormControl>
            <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">אתר הבניה הנוכחי</FormLabel>
            <Select dir='ltr' placeholder='תבחור אתר בניה' value={currentWorkSite} onChange={(e) => {setCurrentWorkSite(e.target.value);}}>
              {workSites?.map((project:any) => (
               <option key={project._id} value={project._id}>
                  {project.name} 
                </option> 
              ))}
            </Select>
        </FormControl><br/>

        <br/><br/>
        {isAddButtonLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
          <Button type='submit' sx={{
              boxShadow: '10px',
              backgroundColor: '#4caf50',
              border: 'none',
              color: 'white',
              textAlign: 'center',
              fontSize: '16px',
              borderRadius: '20px',
              width: '100%',
            }} onClick={handleSubmit}>עריכה
          </Button>
        )}
        </>)}
    </Box>
  )
}

export default EditWorker
