import { AlertDialog, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react';
import React, { useRef } from 'react'

interface DeleteConfirmationDialogProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  yesFunction: (parm:String) => void;
  itemId: String;
}

function DeleteConfirmationDialog({ title, isOpen, onClose, yesFunction, itemId }:DeleteConfirmationDialogProps) {
    const leastDestructiveRef = useRef<HTMLButtonElement | null>(null);

  return (
    <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={leastDestructiveRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogFooter>
            <Button onClick={onClose}>
              ביטול
            </Button>
            <Button colorScheme='red' ml={3} onClick={ () => {
                yesFunction(itemId)
                onClose()
            }}>
              כן
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>
  )
}

export default DeleteConfirmationDialog;