import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Thead, Tbody, Tr, Td, Input, Button, Text, Box, Select, Textarea, Spinner, useColorMode, useToast, } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';
import { url } from '../../assets/urlAdress';

interface Car {
  _id: any;
  name: string;
  number: number;
  fuelExpenses: number;
  faultsExpenses: number;
  currHolder: string;
  status: Boolean;
  usage: any;
  expenses: any;
  workSite: any;
  currFuelConsumption: number;
}

function CarUsageAllCars() {
    const { setIsAuthenticated } = useAuth();

    const navigate = useNavigate();
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(true);
    const [cars, setCars] = useState<Car[]>([]);
    const [editedCars, setEditedCars] = useState<Car[]>([]);
    const [workSites, setWorkSites] = useState<any[]>();
    const [isAddButtonLoading, setIsAddButtonLoading] = useState(false);
    const { colorMode  } = useColorMode();
    const headerColor = colorMode === "dark" ? "black" : "#f6f6f6f6";
    
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${url}/Cars`, { withCredentials: true });
        const projects = await axios.get(`${url}/Projects`, { withCredentials: true });
        const data = response.data;
        setCars(data);
        setEditedCars([...data]);
        setWorkSites(projects.data);
        setIsLoading(false);
      } catch (error:any) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
        if (error?.response?.status === 401) { setIsAuthenticated(false); }
      }
    };
  
    useEffect(() => {
      fetchData();
    }, []);
  
    const handleEdit = (carIndex: number, field: keyof Car, value: string) => {
      // Update the edited worker data using the worker's index as the identifier
      const updatedCars = [...editedCars];
      const editedWorker = { ...updatedCars[carIndex] };
      editedWorker[field] = value as never;
      updatedCars[carIndex] = editedWorker;
      setEditedCars(updatedCars);
    };
      
    const handleSubmit = async () => {
      try {
        setIsAddButtonLoading(true);
        const response = await axios.post(`${url}/Cars/CarUsageAllCars`, 
        {editedCars},
        { withCredentials: true });
        if (response.status === 200) {
          toast({
            title: 'שימוש רכבים הוסף בהצלחה',
            status: 'success',
            duration: 5000,
            position: 'top-right',
            isClosable: true,
          });
        }
        navigate('/Cars');
      } catch (error:any) {
        console.error('Error fetching data:', error);
        toast({
          title: 'חוסר/טעות בנתונים',
          description: error?.response?.data?.message,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      } finally { setIsAddButtonLoading(false); }
    };
  
    return (
      <Box dir='rtl'>
        {isLoading ? (
            <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                padding="2rem"
                borderRadius="8px"
                zIndex="1000"
            >
               <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
            </Box>
        ) : (
            <Box>
              <Text fontSize='3xl' textAlign='center' fontWeight="bold">הוספת ימי עבודה</Text><br/>
              <Table>
                <Thead sx={{bgColor: headerColor}}>
                  <Tr>
                    <Td fontWeight='bold'>שם רכב</Td>
                    <Td fontWeight='bold'>מספר רכב</Td>
                    <Td fontWeight='bold'>נהג</Td>
                    <Td fontWeight='bold'>א.בניה</Td>
                    <Td fontWeight='bold'>צריכת דלק</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {cars.map((car, index) => (
                    <Tr key={car._id}>
                      <Td>{car.name} </Td>
                      <Td>{car.number}</Td>
                      <Td>
                        <Input
                          type="text"
                          value={editedCars[index].currHolder}
                          onChange={(e) => handleEdit(index, 'currHolder', e.target.value)}
                        />
                      </Td>
                      <Td>
                        <Select value={editedCars[index]?.workSite?._id} onChange={(e) => handleEdit(index, 'workSite', e.target.value)}
                         dir='ltr' placeholder="תבחור סוג החופש">
                            {workSites?.map((project:any) => (
                              <option value={project._id}>{project.name}</option>
                            ))}
                        </Select>
                      </Td>
                      <Td>
                        <Input
                          type="number"
                          value={editedCars[index].currFuelConsumption}
                          onChange={(e) => handleEdit(index, 'currFuelConsumption', e.target.value)}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <br/>
              {isAddButtonLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
                <Button type='submit' sx={{
                    boxShadow: '10px',
                    backgroundColor: '#4caf50',
                    border: 'none',
                    color: 'white',
                    textAlign: 'center',
                    fontSize: '16px',
                    borderRadius: '20px',
                    width: '100%',
                  }} onClick={handleSubmit}>הוספה
                </Button>
              )}
            </Box>
        )}
      </Box>
    );
}

export default CarUsageAllCars
