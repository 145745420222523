import axios from 'axios';
import { createContext, useContext, useState, useEffect } from 'react';
import { url } from '../assets/urlAdress'

const AuthContext = createContext();

export function AuthProvider({ children }) {
  // State to manage authentication status
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Fetch the authentication status from the backend when the component mounts
  useEffect(() => {
    async function checkAuthentication() {
      try {
        const response = await axios.get(`${url}/auth/status`, { withCredentials: true });

        console.log('response isAuth ?');
        console.log(response)

        if (response.status === 200) {
          // Assuming your server responds with JSON data like { isAuthenticated: true }
          //const data = await response.json();
          console.log(' Yes Its Authntcated ')
          // Check if isAuthenticated is true in the response data
          setIsAuthenticated(true);
        } else {
          console.log(' NOOOOT Authntcated ')
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error checking authentication status:', error);
        setIsAuthenticated(false);
      }
    }

    checkAuthentication();
  }, []); // Empty dependency array to run this effect only on mount

  // Function to handle logout
  const logout = async () => {
    try {
      // Send a request to the logout route on the server
      await fetch(`${url}/auth/logout`, {
        credentials: 'include',
      });

      // Reset the authentication status to false
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
