import { Box, Text, FormControl, FormLabel, Input, Button, Spinner, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';
import { url } from '../../assets/urlAdress';

function EditCar() {
  const { setIsAuthenticated } = useAuth();
  const carId = useParams();
  const navigate = useNavigate();
  const toast = useToast()

  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}/Cars/${carId.id}`, { withCredentials: true });
      if (response.status === 200) {
        const data = response.data;
        setCarID(data._id)
        setName(data.name);
        setNumber(data.number);
        setFuelExpenses(data.fuelExpenses);
        setFaultsExpenses(data.faultsExpenses);
        setCurrHolder(data.currHolder);
        setIsLoading(false);
      }
    } catch (error:any) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      if (error?.response?.status === 401) { setIsAuthenticated(false); }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [carID, setCarID] = useState('')
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [fuelExpenses, setFuelExpenses] = useState('')
  const [faultsExpenses, setFaultsExpenses] = useState('')
  const [currHolder, setCurrHolder] = useState('')

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const handleSubmit = async () => {
    try {
        setIsSubmitButtonLoading(true);
        const response = await axios.patch(`${url}/cars/${carID}`, {name, number, fuelExpenses, faultsExpenses, currHolder,}, { withCredentials: true });
        if (response.status === 200) {
          toast({
            title: 'פרטי הרכב עודכנו',
            status: 'success',
            duration: 5000,
            position: 'top-right',
            isClosable: true,
          });
          navigate(`/cars/${carID}`);
        }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'חוסר/טעות בנתונים',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    } finally { setIsSubmitButtonLoading(false); }
  };

  return (
    <Box dir='rtl' margin={{ base: 0, md: 5 }}>
        {isLoading ? (
            <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                padding="2rem"
                borderRadius="8px"
                zIndex="1000"
            >
                <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
            </Box>
        ) : (
            <Box>
                <Text fontSize='3xl' textAlign='center' fontWeight="bold">עריכת רכב</Text><br/>
                <FormControl>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold" >
                      שם רכב
                    </FormLabel>
                  <Input value={name} name='name' type='text' onChange={(e:any) => setName(e.target.value)}/>
                </FormControl><br/>

                <FormControl>
                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מספר רכב</FormLabel>
                  <Input value={number} name='text' type='text' onChange={(e:any) => setNumber(e.target.value)}/>
                </FormControl><br/>

                <FormControl>
                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">הוצאות דלק</FormLabel>
                  <Input value={fuelExpenses} name='text' type='text' onChange={(e:any) => setFuelExpenses(e.target.value)}/>
                </FormControl><br/>

                <FormControl>
                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">הוצאות תיקונים</FormLabel>
                  <Input value={faultsExpenses} name='text' type='text' onChange={(e:any) => setFaultsExpenses(e.target.value)}/>
                </FormControl><br/>

                <FormControl>
                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מחזיק נוכחי</FormLabel>
                  <Input value={currHolder} name='text' type='text' onChange={(e:any) => setCurrHolder(e.target.value)}/>
                </FormControl>

                <br/><br/>
                {isSubmitButtonLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
                  <Button type='submit' sx={{
                      boxShadow: '10px',
                      backgroundColor: '#4caf50',
                      border: 'none',
                      color: 'white',
                      textAlign: 'center',
                      fontSize: '16px',
                      borderRadius: '20px',
                      width: '100%',
                  }} onClick={handleSubmit}>עריכה
                  </Button>
                )}
            </Box>
        ) }
    </Box>
  )
}

export default EditCar;