import React, { useEffect } from 'react';
import { useAuth } from '../Auth/AuthContext'
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { url } from '../assets/urlAdress';

function Login() {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is authenticated when the component mounts
    if (isAuthenticated) {
      
      // Redirect to the home page if authenticated
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const onSuc = () => {
    window.open(`${url}/auth/google`, "_self")
  }

  return (
    <div>
      <h1>Google Sign-In Example</h1>
      <Button onClick={onSuc}>login</Button>
  </div>
  );
}

export default Login;
