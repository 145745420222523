import { Box, Button, FormControl, FormLabel, Input, Spinner, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../Auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { url } from '../../assets/urlAdress';

function EditSupplierOrder() {
  const { setIsAuthenticated } = useAuth();
  const parmsId = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [order, setOrder] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const [payed, setPayed] = useState(0);
  const [invoicingNum, setInvoicingNum] = useState('')
  
  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}/Suppliers/${parmsId.id}/getSupplierOrder/${parmsId.orderId}`, { withCredentials: true });
      const data = response.data;
      console.log(data)
      setOrder(data);
      setPayed(data.Payed);
      setInvoicingNum(data.invoicingNum);
      setIsLoading(false);
    } catch (error:any) {
      setIsLoading(false);
      console.error('Error fetching data:', error);
      if (error?.response?.status === 401) { setIsAuthenticated(false); }
    }
  };

  useEffect(() => {
        fetchData();
  }, []);

  const [isAddButtonLoading, setIsAddButtonLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsAddButtonLoading(true);
      const response = await axios.patch(`${url}/Suppliers/${parmsId.id}/editOrder/${parmsId.orderId}`,  
                      {Payed: payed, invoicingNum}, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'הזמנה עודכה',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        navigate(`/Suppliers/${parmsId.id}/${parmsId.orderId}`);
      }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'Error',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    } finally { setIsAddButtonLoading(false) }
  }

  return (
    <Box dir='rtl'>
      {isLoading ? (
            <Box
              position="fixed"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              padding="2rem"
              borderRadius="8px"
              zIndex="1000"
            >
              <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
            </Box>
        ): (
            <Box>
              <Text fontSize='3xl' textAlign='center' fontWeight="bold">עריכת הזמנה מספר: {order?.invoicingNum}, מספק: {order.supplier.name}</Text><br/>
              <FormControl>
                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
                      מס" הזמנה   
                  </FormLabel>
                <Input value={invoicingNum} onChange={(e)=>setInvoicingNum(e.target.value)} type='text' />
              </FormControl><br/>

              <FormControl>
                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">
                      שולם
                  </FormLabel>
                  <Stack direction='row' mr={3}>
                    <Text fontSize='25'>{calculateOrderTotal(order) * 1.17}₪/</Text>
                    <Input value={payed} onChange={(e)=>setPayed(Number(e.target.value))} type='number' />
                  </Stack>
              </FormControl><br/>
              
              {isAddButtonLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
                <Button type='submit' sx={{
                  boxShadow: '10px',
                  backgroundColor: '#4caf50',
                  border: 'none',
                  color: 'white',
                  textAlign: 'center',
                  fontSize: '16px',
                  borderRadius: '20px',
                  width: '100%',
                }} onClick={handleSubmit}>עריכה</Button>
              )}
                
            </Box>
        )}
    </Box>
  )
}

export default EditSupplierOrder;

function calculateOrderTotal(order:any) {
  let total = 0;

  for (const product of order.products) {
    const pricePerUnit = product.Price;
    const quantity = product.quantity;
    const discount = (1 - (product.deduction/100));
    const productTotal = pricePerUnit * discount * quantity;
    total += productTotal;
  }

  return total;
}