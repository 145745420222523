import { Box, Button, Icon, IconButton, Input, InputGroup, InputLeftElement, Spinner, Stack, Table, Tag, TagLabel, Tbody, Td, Text, Thead, Tr, useColorMode, useToast } from '@chakra-ui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import { FaSearch } from 'react-icons/fa'
import { FiPlus } from 'react-icons/fi'
import { MdDeleteOutline } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import DeleteConfirmationDialog from '../../components/Dialogs/DeleteConfirmationDialog'
import { useAuth } from '../../Auth/AuthContext';
import { url } from '../../assets/urlAdress';

interface Project {
  _id: any;
  name: string;
  location: string;
  status: string;
  contact: any;
  startDate: any;
  planEndDate: any;
  endDate: any;
  assessment: number;
  expenses: number;
  earn: number;
}

function Projects() {
  const { setIsAuthenticated } = useAuth();
  const { colorMode  } = useColorMode();
  const headerColor = colorMode === "dark" ? "black" : "#f6f6f6f6";
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate();
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  const fetchData = async () => {
    try {
      console.log(url)
      const response = await axios.get(`${url}/Projects`, { withCredentials: true });
      const data = response.data;
      setProjects(data);
      setIsLoading(false);
    } catch (error:any) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      if (error?.response?.status === 401) { setIsAuthenticated(false); }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteButtonClick = async (itemId: any) => {
    try {
        const response = await axios.delete(`${url}/Projects/${itemId}`, { withCredentials: true });
        if (response.status === 200) {
          toast({
            title: 'פרויקט נמחק',
            status: 'success',
            duration: 5000,
            position: 'top-right',
            isClosable: true,
          });
          fetchData()
        }
    } catch (error:any) {
        console.error('Error creating car:', error);
        toast({
          title: 'Error',
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
    }
  }
  
  const [currentId, setCurrentId] = useState(false);

  return (
    <Box dir="rtl">
      {isLoading ? (
          <Box
            position="fixed"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            padding="2rem"
            borderRadius="8px"
            zIndex="1000">
            <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
          </Box>
        ) : ( <>
        <Box>
            <Text fontSize='3xl' textAlign='center' fontWeight="bold">פרויקטים</Text><br/>
            <Stack spacing={2} direction='row'>
                <InputGroup mr={3}>
                    <InputLeftElement><Icon as={FaSearch} /></InputLeftElement>
                    <Input placeholder='חיפוש' onChange={(e) => setSearchInput(e.target.value)} />
                </InputGroup>
                <Button mr={2} colorScheme='teal' sx={{borderRadius: 50}} onClick={()=> navigate('/Projects/CreateProject')}>
                    <Icon as={FiPlus} sx={{fontSize: '20'}}/>
                    <Text sx={{ fontFamily:'Arial', display: {base: 'none', sm: 'block'} }}>הוספת פרויקט</Text>
                </Button>
            </Stack>
        </Box><br/>
        <Table>
            <Thead sx={{bgColor: headerColor}}>
                <Tr>
                    <Td fontWeight="bold">א.בניה</Td>
                    <Td fontWeight="bold">מיקום</Td>
                    <Td fontWeight="bold">אנשי קשר</Td>
                    <Td fontWeight="bold">הכנסות</Td>
                    <Td fontWeight="bold">הוצאות</Td>
                    <Td fontWeight="bold">סטטוס</Td>
                    <Td fontWeight="bold">פעולות</Td>
                </Tr>
            </Thead>
            <Tbody>
                {projects.filter((project:any) => {
                  return (project.name.toLowerCase().includes(searchInput.toLowerCase()));
                }).map((project:any) => (
                  <>
                    <Tr key={project._id}>
                        <Td>{project.name}</Td>
                        <Td>{project.location}</Td>
                        <Td>{project.contact.manger.name}-{project.contact.manger.phoneNumber}</Td>
                        <Td>{project.earn}₪</Td>
                        <Td>{project.expenses}₪</Td>
                        <Td>
                          {project.status === 'In Progress' && (
                            <Tag size="lg" colorScheme="blue" borderRadius="full">
                              <TagLabel>בתהליך</TagLabel>
                            </Tag>
                          )}
                          {project.status === 'Completed' && (
                            <Tag size="lg" colorScheme="green" borderRadius="full">
                              <TagLabel>הסתיים</TagLabel>
                            </Tag>
                          )}
                          {project.status === 'On Hold' && (
                            <Tag size="lg" colorScheme="yellow" borderRadius="full">
                              <TagLabel>בהשהייה</TagLabel>
                            </Tag>
                          )}
                          {project.status === 'Canceled' && (
                            <Tag size="lg" colorScheme="red" borderRadius="full">
                              <TagLabel>נבטל</TagLabel>
                            </Tag>
                          )}
                        </Td>
                        <Td>
                          <Stack spacing={3} direction='row'>
                            <IconButton
                              colorScheme='teal'
                              aria-label='Call Sage'
                              fontSize='20px'
                              icon={<AiOutlineEye />}
                              onClick={()=> navigate(`/Projects/${project._id}`)}
                            />
                            <IconButton
                              colorScheme='red'
                              aria-label='Call Sage'
                              fontSize='20px'
                              icon={<MdDeleteOutline />}
                              onClick={() => setCurrentId(project._id)}
                            />
                          </Stack>
                        </Td>
                    </Tr>
                    {currentId === project._id && <DeleteConfirmationDialog 
                      title={`האם אתה מאשר למחוק פרויקט: ${project.name}`}
                      isOpen={currentId}
                      onClose={() => {
                        setCurrentId(false)
                        fetchData();
                      }}
                      yesFunction={handleDeleteButtonClick}
                      itemId={project._id}
                    />}
                    </>
                ))}
            </Tbody>
        </Table>
        </>)}
    </Box>
  )
}

export default Projects;
