import {
    Box,
    CloseButton,
    Flex,
    useColorModeValue,
    BoxProps,
} from '@chakra-ui/react'

import { IconType } from 'react-icons'
import { Link } from 'react-router-dom'
import NavItem from './NavItem';
import ColorModeToggle from '../Toggles/ColorModeToggle';
import { useAuth } from '../../Auth/AuthContext';
import { FiLogOut } from 'react-icons/fi';

interface LinkItemProps {
  name: string;
  icon: IconType;
  to: string;
}

interface SidebarProps extends BoxProps {
    LinkItems: Array<LinkItemProps>;
    onClose: () => void;
}
  
function SidebarContent ({ onClose, LinkItems, ...rest }: SidebarProps) {
  const { logout } = useAuth();
  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <img src="https://cdn.discordapp.com/attachments/1091485120151699497/1112482080325120121/image.png"
          alt="logoSmall" width="140px" />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link, index) => (
        <Link key={index} to={`/${link.to}`}>
            <NavItem icon={link.icon} onClose={onClose}>
              {link.name}
            </NavItem>
        </Link>
      ))}
      <Link to={'/login'}><NavItem icon={FiLogOut} onClose={onClose} onClick={logout}>התנתק</NavItem></Link>
      <br/><br/>
      <Box ml={10}><ColorModeToggle/></Box>
    </Box>
  )
}

export default SidebarContent;