import { Box, Button, Icon, IconButton, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Select, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tag, TagLabel, Tbody, Td, Text, Thead, Tooltip, Tr, useColorMode, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaSearch } from "react-icons/fa";
import { FiPlus } from 'react-icons/fi';
import { AiOutlineEye, AiOutlineFilter } from 'react-icons/ai';
import { MdDeleteOutline } from 'react-icons/md'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmationDialog from '../../components/Dialogs/DeleteConfirmationDialog';
import { useAuth } from '../../Auth/AuthContext';

import { url } from '../../assets/urlAdress';
import { GrPowerCycle } from 'react-icons/gr';

function Workers() {
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [workers, setWorkers] = useState<any[]>([]);
  const [workDays, setWorkDays] = useState<any[]>([]);
  const [workSites, setWorkSites] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const workersResponse = await axios.get(`${url}/Workers`, { withCredentials: true });
      const workDaysResponse = await axios.get(`${url}/WorkDays`, { withCredentials: true });
      const workSites = await axios.get(`${url}/Projects`, { withCredentials: true });
      const data = workersResponse.data;
      setWorkers(data);
      setWorkDays(workDaysResponse.data);
      setWorkSites(workSites.data)
      setIsLoading(false);
    } catch (error:any) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      if (error?.workersResponse?.status === 401) { setIsAuthenticated(false); }
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteButtonClick = async (itemId: any) => {
    try {
        const response = await axios.delete(`${url}/Workers/${itemId}`, { withCredentials: true });
        if (response.status === 200) {
          toast({
            title: 'עובד נמחק',
            status: 'success',
            duration: 5000,
            position: 'top-right',
            isClosable: true,
          });
          fetchData();
        }
    } catch (error:any) {
        console.error('Error creating car:', error);
        toast({
          title: 'Error',
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
    }
  }
  const handleDeleteDayOffSubmission = async (itemId:any) => {
    try {
      const response = await axios.delete(`${url}/Workers/${itemId}/WorkDay/deleteWorkDay/${currentWorkDayId}`
      , { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'יום עבודה נמחקה בהצלחה',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        fetchData();
      }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'Error',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const { colorMode  } = useColorMode();
  const headerColor = colorMode === "dark" ? "black" : "#f6f6f6f6";
  const [searchInput, setSearchInput] = useState('');
  const [currentId, setCurrentId] = useState(false);

  const [currentWorkDayId, setCurrentWorkDayId] = useState(false);

  const [currWorker, setCurrWorker] = useState(null);
  const [currWorkSite, setCurrWorkSite] = useState(null);

  const [day, setDay] = useState(0);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);

  const sumOfWorkDays = workDays?.filter((workDay) => {
    if (day !== null && day !== 0 && workDay?.Date?.day !== day) {
      return false;
    }
    if (month !== null && month !== 0 && workDay?.Date?.month !== month) {
      return false;
    }
    if (year !== null && year !== 0 && workDay?.Date?.year !== year) {
      return false;
    }
    if (currWorkSite !== null && currWorkSite !== workDay?.workSite?.name) {
      return false;
    }
    if (currWorker !== null && currWorker !== workDay?.worker?.name) {
      return false;
    }
    return true
  }).reduce((sum, workDay) => sum + workDay.payment, 0);

  return (
    <Box dir="rtl">
      {isLoading ? (
            <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                padding="2rem"
                borderRadius="8px"
                zIndex="1000"
            >
               <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
            </Box>
        ) : (
          <>
        <Box>
            <Text fontSize='3xl' textAlign='center' fontWeight="bold">עובדים</Text><br/>
            <Stack spacing={2} direction='row'>
                <InputGroup mr={3}>
                    <InputLeftElement><Icon as={FaSearch} /></InputLeftElement>
                    <Input placeholder='חיפוש' onChange={(e) => setSearchInput(e.target.value)}/>
                </InputGroup>
                <Button mr={2} colorScheme='teal' sx={{borderRadius: 50}} onClick={() => navigate('/Workers/CreateWorker')}>
                    <Icon as={FiPlus} sx={{fontSize: '20'}}/>
                    <Text sx={{ fontFamily:'Arial', display: {base: 'none', sm: 'block'} }}>הוספת עובד</Text>
                </Button>
                <Button bgColor='blue.400' mr={2} colorScheme='teal' sx={{borderRadius: 50, display: { base: 'none', sm: 'block' }}} onClick={() => navigate('/Workers/workDayAllWorkers')}>
                    <Text ml={7} sx={{ fontFamily:'Arial', display: {base: 'none', sm: 'block'} }}>הוספת ימי עבודה</Text>
                </Button>
            </Stack>
        </Box> <br/>
        <Tabs isFitted>
          <TabList>
            <Tab>עובדים</Tab>
            <Tab>ימי עבודה</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <TableContainer pr={3}>
                <Table variant='simple'>
                  <Thead sx={{bgColor: headerColor}}>
                      <Tr>
                        <Td fontWeight="bold">שם</Td>
                        <Td fontWeight="bold">מס.טל</Td>
                        <Td fontWeight="bold">יומית</Td>
                        <Td fontWeight="bold">סטטוס</Td>
                        <Td fontWeight="bold">א.בניה הנוכחי</Td>
                        <Td fontWeight="bold">פעולות</Td>                                                                                               
                      </Tr>
                  </Thead>
                  <Tbody>{workers.filter((worker) => {
                    return (
                      worker.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                      worker.phoneNumber.toString().toLowerCase().includes(searchInput.toLowerCase())
                    );
                  }).map((worker) => (
                    <Tr>           
                      <Td>{worker.name}</Td>    
                      <Td>{worker.phoneNumber}</Td>
                      <Td>{worker.dailyPayment}₪</Td>
                      <Td>
                        <Tag
                          size='lg'
                          colorScheme={ worker.status === 'ableToWork' ? 'green'
                            : worker.status === 'vacation' ? 'red' : 'orange' }
                          borderRadius='full'>
                          <TagLabel>
                            { worker.status === 'ableToWork' ? 'זמין'
                             : worker.status === 'vacation' ? 'חופשה' : 'חופש מחלה' }
                          </TagLabel>
                        </Tag>
                      </Td>
                      <Td>{worker?.currentWorkSite?.name || 'אין'}</Td>
                      <Td>
                        <Stack spacing={3} direction='row'>
                          <IconButton
                            variant='solid'
                            colorScheme='teal'
                            aria-label='Call Sage'
                            fontSize='20px'
                            icon={<AiOutlineEye />}
                            onClick={() => navigate(`/Workers/${worker._id}`)}
                          />
                          <IconButton
                            colorScheme='red'
                            aria-label='Call Sage'
                            fontSize='20px'
                            icon={<MdDeleteOutline />}
                            onClick={() => setCurrentId(worker._id)}
                          />
                        </Stack>
                      </Td>
                      {currentId === worker._id && <DeleteConfirmationDialog
                        title={`האם אתה רוצה למחוק העובד: ${worker.name}`}
                        isOpen={currentId}
                        onClose={() => setCurrentId(false)}
                        yesFunction={handleDeleteButtonClick}
                        itemId={worker._id}
                      />}
                    </Tr>
                  ))}</Tbody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel>
              <Stack spacing={2} direction='row'>
                <Input placeholder="שנה" value={year !== 0 ? year : ''} onChange={(e)=> setYear(Number(e.target.value))}/>
                <Select value={month} dir='ltr' onChange={(e)=> setMonth(Number(e.target.value))}>
                  <option value={0}>חודש</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                </Select>
                <Input placeholder="יום" value={day !== 0 ? day : ''} onChange={(e)=> setDay(Number(e.target.value))}/>
                <IconButton onClick={() => {setMonth(0); setYear(0); setDay(0);}} bgColor='white' aria-label='Call Sage' fontSize='20px' icon={<GrPowerCycle/>}/>
              </Stack> <br/>
              <TableContainer pr={3}>
                <Table variant='simple'>
                  <Thead sx={{bgColor: headerColor}}>
                      <Tr>
                        <Td>
                          <Menu>
                            <MenuButton cursor="pointer">
                              <Stack direction='row-reverse'>
                                <span>{currWorker ? currWorker : 'עובד'}</span>
                                <AiOutlineFilter/>
                              </Stack>
                            </MenuButton>
                            <MenuList>
                              <MenuItem onClick={()=>setCurrWorker(null)}>הכל</MenuItem>
                              {workers?.map((worker:any) => (
                                <MenuItem onClick={()=>setCurrWorker(worker.name)}>{worker.name}</MenuItem>
                              ))}
                            </MenuList>
                          </Menu>
                        </Td>
                        <Td fontWeight="bold">תאריך</Td>
                        <Td fontWeight="bold">תשלום</Td>
                        <Td fontWeight="bold">
                          <Menu>
                            <MenuButton cursor="pointer">
                              <Stack direction='row-reverse'>
                                <span>א.בניה</span>
                                <AiOutlineFilter/>
                              </Stack>
                            </MenuButton>
                            <MenuList>
                              <MenuItem onClick={()=>setCurrWorkSite(null)}>הכל</MenuItem>
                              {workSites?.map((workSite:any) => (
                                <MenuItem onClick={()=>setCurrWorkSite(workSite.name)}>{workSite.name}</MenuItem>
                              ))}
                            </MenuList>
                          </Menu>
                        </Td>
                        <Td fontWeight="bold">תיאור</Td>
                        <Td fontWeight="bold">פעולות</Td>                                                                                          
                      </Tr>
                  </Thead>
                  <Tbody>
                    {workDays.filter((workDay) => {
                      if (day !== null && day !== 0 && workDay?.Date?.day !== day) {
                        return false;
                      }
                      if (month !== null && month !== 0 && workDay?.Date?.month !== month) {
                        return false;
                      }
                      if (year !== null && year !== 0 && workDay?.Date?.year !== year) {
                        return false;
                      }
                      if (currWorkSite !== null && currWorkSite !== workDay?.workSite?.name) {
                        return false;
                      }
                      if (currWorker !== null && currWorker !== workDay?.worker?.name) {
                        return false;
                      }
                      return true
                    }).map((workDay:any) => (
                    <Tr key={workDay._id}>
                      <Td>{workDay?.worker?.name}</Td>
                      <Td>{workDay.Date.day}/{workDay.Date.month}/{workDay.Date.year}</Td>
                      <Td>{workDay.payment}₪</Td>
                      <Td>{workDay?.workSite?.name || 'אין'}</Td>
                      <Td>
                        <Tooltip
                          label={
                            <Box dir="rtl">
                              {workDay?.description}
                            </Box>
                          }
                          fontSize="md"
                          hasArrow
                          placement="top">
                          <Text fontWeight="bold" fontSize="lg" fontFamily="arial">תיאור</Text>
                        </Tooltip>
                      </Td>
                      <Td>
                        <Stack spacing={3} direction='row'>
                          <IconButton
                            colorScheme='red'
                            aria-label='Call Sage'
                            fontSize='20px'
                            icon={<MdDeleteOutline />}
                            onClick={() => setCurrentWorkDayId(workDay._id)}
                          />
                        </Stack>
                      </Td>
                      {currentWorkDayId === workDay._id && <DeleteConfirmationDialog
                        title={`האם אתה רוצה למחוק יום עבודה ל ${workDay?.worker?.name} בתאריך ${workDay.Date.day}/${workDay.Date.month}/${workDay.Date.year}`}
                        isOpen={currentWorkDayId}
                        onClose={() => setCurrentWorkDayId(false)}
                        yesFunction={handleDeleteDayOffSubmission}
                        itemId={workDay?.worker?._id}
                      />}
                    </Tr>
                    ))}
                    <Tr>
                      <Td></Td>
                      <Td fontWeight='bold'>סכום:</Td>
                      <Td fontWeight='bold'>{sumOfWorkDays}₪</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>
          </TabPanels>
        </Tabs>

        </>)}
    </Box>
  )
}

export default Workers;