import React, { useEffect, useState } from 'react'
import { Spinner, Box, Button, Flex, Heading, Icon, IconButton, Stack, Tab, TabList, TabPanel, TabPanels, Table, Tabs, Tag, TagLabel, Tbody, Td, Text, Thead, Tr, useColorMode, useToast } from '@chakra-ui/react';
import { FaEdit  } from 'react-icons/fa';
import { AiOutlinePlus } from 'react-icons/ai';
import { MdDeleteOutline } from 'react-icons/md';
import AddCarUsageModal from '../../components/Modals/CarModals/AddCarUsageModal';
import AddProductModal from '../../components/Modals/addProductModal';
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import DeleteConfirmationDialog from '../../components/Dialogs/DeleteConfirmationDialog';
import { useAuth } from '../../Auth/AuthContext';
import { url } from '../../assets/urlAdress';

interface Car {
  _id: any;
  name: string;
  number: number;
  fuelExpenses: number;
  faultsExpenses: number;
  currHolder: string;
  status: Boolean;
  usage: any;
  expenses: any;
}

function CarDetails() {
  const { setIsAuthenticated } = useAuth();
  const carId = useParams();
  const [carData, setCarData] = useState<Car>()
  const toast = useToast()
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${url}/cars/${carId.id}`, { withCredentials: true });
      const SuppliersList = await axios.get(`${url}/Suppliers`, { withCredentials: true });
      const data = response.data;
      setCarData(data);
      console.log(data)
      setSuppliers(SuppliersList.data)
      setIsLoading(false);
    } catch (error:any) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      if (error?.response?.status === 401) { setIsAuthenticated(false); }
    }
  };
    useEffect(() => {
    fetchData();
  }, []);

  // Handle Submission
  const handleDeleteUsageButtonClick = async (itemId: any) => {
    try {
        const response = await axios.delete(`${url}/cars/${carId.id}/usage/delete/${itemId}`, { withCredentials: true });
        if (response.status === 200) {
          toast({
            title: 'שימוש הרכב נמחק',
            status: 'success',
            duration: 5000,
            position: 'top-right',
            isClosable: true,
          });
          fetchData();
        }
    } catch (error:any) {
        console.error('Error creating car:', error);
        toast({
          title: 'חוסר/טעות בנתונים',
          description: error?.response?.data?.message,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
    }
  }
  const handleDeleteExpenseButtonClick = async (itemId: any) => {
    try {
      const response = await axios.delete(`${url}/cars/${carId.id}/expense/delete/${itemId}`, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'הוצאת הרכב נמחקה',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        fetchData();
      }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'חוסר/טעות בנתונים',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    }
  }
  const handleAddExpense = async (data:any) => {
    try {
      let { date, product, supplier } = data;
      const response = await axios.post(`${url}/Cars/${carId.id}/expense/create`, { date, product, supplier }, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'הוצאוצ הוספו בהצלחה',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      }
      fetchData();
      setAddExpenseModal(false);
    } catch (error:any) {
      console.log('Error: ' + error)
      toast({
        title: 'חוסר/טעות בנתונים',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const { colorMode  } = useColorMode();
  const headerColor = colorMode === "dark" ? "black" : "#EFEFEFF6";
  const [Suppliers, setSuppliers] = useState<any[]>()
  const [addUsageModal, setAddUsageModal] = useState(false)
  const [addExpenseModal, setAddExpenseModal] = useState(false)

  const [isLoading, setIsLoading] = useState(true);

  const [currentUsageId, setCurrentUsageId] = useState(false);
  const [currentExpenseId, setCurrentExpenseId] = useState(false);
  
  return (
    <Box dir="rtl">
      {isLoading ? (
            <Box
              position="fixed"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              padding="2rem"
              borderRadius="8px"
              zIndex="1000"
            >
              <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
            </Box>
        ) : (
        <>
        <AddCarUsageModal carName={`${carData?.name}, ${carData?.number}`} isOpen={addUsageModal} onClose={()=>{setAddUsageModal(false); fetchData();}} carId={carData?._id} currentDriver={carData?.currHolder || ''}/>
        <AddProductModal title={`הוספת הוצאות לרכב: ${carData?.name}`} isOpen={addExpenseModal} onClose={()=>{setAddExpenseModal(false)}} 
          yesFunction={handleAddExpense} showCar={false} showSupplier={true} showWorkSite={false} suppliers={Suppliers} worksites={[]} cars={[]} />
        <Flex justifyContent="space-between">
          <Heading as='h3' size='lg'>{carData?.name}</Heading>
            <Stack direction={{ base: 'column', md: 'row' }} spacing={3}>
              <Button sx={{ borderRadius: 35 }} onClick={() => navigate(`/Cars/Edit/${carData?._id}`)}>
                <Icon as={FaEdit} sx={{fontSize: '20'}}/>
                <Text sx={{ fontFamily: 'Arial', mr: 2, }}>עריכה</Text>
              </Button>
              <Stack direction='row'>
                <Button sx={{ borderRadius: 35 }} onClick={()=>setAddUsageModal(true)}>
                  <Icon as={AiOutlinePlus} sx={{fontSize: '20'}} />
                  <Text sx={{ fontFamily: 'Arial', mr: 2, }}>הוספת שימוש</Text>
                </Button>
                <Button sx={{ borderRadius: 35 }} onClick={()=>setAddExpenseModal(true)}>
                  <Icon as={AiOutlinePlus} sx={{fontSize: '20'}}/>
                  <Text sx={{ fontFamily: 'Arial', mr: 2, }}>הוספת הוצאות</Text>
                </Button>
              </Stack>
            </Stack>
        </Flex><br/>
        <Flex justifyContent="space-between" direction={{ base: 'column', md: 'row' }}>
          <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>שם רכב: {carData?.name}</Text>
          <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>מספר רכב: {carData?.number}</Text>
          <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>הוצאות דלק: {carData?.fuelExpenses}₪</Text>
          <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>הוצאות תיקונים: {carData?.faultsExpenses}₪</Text>
          <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>מחזיק נוכחי: {carData?.currHolder || "אין"}</Text>
          <Tag
            size='lg'
            colorScheme={carData?.status ? 'green' : 'red'}
            borderRadius='full'>
            <TagLabel>
              מצב: {carData?.status ? 'זמינה' : 'בתיקון'}
            </TagLabel>
          </Tag>
        </Flex><br/>
        <Tabs isFitted>
          <TabList>
            <Tab>שימוש</Tab>
            <Tab>הוצאות</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
            <Table>
              <Thead sx={{bgColor: headerColor}}>
                <Tr>
                  <Td fontWeight="bold">תאריך</Td>
                  <Td fontWeight="bold">א.בניה</Td>
                  <Td fontWeight="bold">נהג</Td>
                  <Td fontWeight="bold">צריכת דלק</Td>
                  <Td fontWeight="bold">פעולות</Td>
                </Tr>
              </Thead>
              <Tbody>
                {carData?.usage.map((usage:any) => (
                  <Tr>
                    <Td>{usage?.Date?.day}/{usage?.Date?.month}/{usage?.Date?.year}</Td>
                    <Td>{usage.workSite || 'אין'}</Td>
                    <Td>{usage.driver || 'אין'}</Td>
                    <Td>{usage.fuelConsumption}₪</Td>
                    <Td>
                      <IconButton
                        colorScheme='red'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<MdDeleteOutline />}
                        onClick={() => setCurrentUsageId(usage._id)}
                      />
                    </Td>
                      {currentUsageId === usage._id && <DeleteConfirmationDialog 
                        title={`האם אתה בטוח שרוצה למחוק השימוש בתאריך: ${usage?.Date?.day}/${usage?.Date?.month}/${usage?.Date?.year}`}
                        isOpen={currentUsageId}
                        onClose={() => setCurrentUsageId(false)}
                        yesFunction={handleDeleteUsageButtonClick}
                        itemId={usage._id}
                      />}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            </TabPanel>
            <TabPanel>
              <Table>
                <Thead sx={{bgColor: headerColor}}>
                  <Tr>
                    <Td fontWeight="bold">תאריך</Td>
                    <Td fontWeight="bold">ספאק</Td>
                    <Td fontWeight="bold">שם מוצר</Td>
                    <Td fontWeight="bold">מספר פריט</Td>
                    <Td fontWeight="bold">מחיר ליחיד</Td>
                    <Td fontWeight="bold">%</Td>
                    <Td fontWeight="bold">מחיר נטו</Td>
                    <Td fontWeight="bold">כמות</Td>
                    <Td fontWeight="bold">סב"כ בש"ח</Td>
                    <Td fontWeight="bold">כולל מע"ם</Td>
                    <Td fontWeight="bold">מחיקה</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {carData?.expenses.map((expense:any, index:any) => (
                    <Tr key={index}>
                      <Td>{expense.Date?.day}/{expense.Date?.month}/{expense.Date?.year}</Td>
                      <Td>{expense.supplier}</Td>
                      <Td>{expense.name}</Td>
                      <Td>{expense.Code}</Td>
                      <Td>{expense.Price}₪</Td>
                      <Td>{expense.deduction}%	</Td>
                      <Td>{Number(expense.Price * (1 - (expense.deduction/100))).toFixed(2)}₪</Td>
                      <Td>{expense.quantity}</Td>
                      <Td>{Number(expense.Price * (1 - (expense.deduction/100)) * expense.quantity).toFixed(2)}₪</Td>
                      <Td>{Number(expense.Price * (1 - (expense.deduction/100)) * expense.quantity * 1.17).toFixed(2)}₪</Td>
                      <Td>
                        <IconButton
                          colorScheme='red'
                          aria-label='Call Sage'
                          fontSize='20px'
                          icon={<MdDeleteOutline />}
                          onClick={() => setCurrentExpenseId(expense._id)}
                        />
                      </Td>
                      {currentExpenseId === expense._id && <DeleteConfirmationDialog 
                        title={`האם אתה בטוח שרוצה למחוק המוצר: ${expense.name}, ${expense.Code}`}
                        isOpen={currentExpenseId}
                        onClose={() => setCurrentExpenseId(false)}
                        yesFunction={handleDeleteExpenseButtonClick}
                        itemId={expense._id}
                      />}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
      )}
    </Box>
  )
}

export default CarDetails;