import React, { useState, useEffect } from 'react'
import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, FormControl, FormLabel, Stack, Input, Select, useBreakpointValue, Textarea, useToast, Spinner } from '@chakra-ui/react'
import axios from 'axios';
import { url } from '../../../assets/urlAdress';

interface AddWorkDayProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  workerId: String;
  currWorkSite: any
  dayPayment: number;
  yesFunction: () => void;
}

function AddWorkDayModal({ title, isOpen, onClose, workerId, currWorkSite, dayPayment, yesFunction}:AddWorkDayProps) {
  const modalSize = useBreakpointValue({ base: "full", md: "3xl" });
  const [workSites, setWorkSites] = useState<any[]>()
  const toast = useToast()

  useEffect(() => {
      const fetchData = async () => {
        try {
          const workSiteResponse = await axios.get(`${url}/Projects`, { withCredentials: true });
          if (workSiteResponse.status === 200) {
            setWorkSites(workSiteResponse.data);
          }
          setLocalCurrWorkSite(currWorkSite);
          setPayment(dayPayment);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
  }, [currWorkSite, dayPayment]);
  

  const [day, setDay] = useState((new Date()).getDate());
  const [month, setMonth] = useState((new Date()).getMonth() + 1);
  const [year, setYear] = useState((new Date()).getFullYear());

  const [description, setDescription] = useState('')

  const [localCurrWorkSite, setLocalCurrWorkSite] = useState();
  const [payment, setPayment] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(`${url}/Workers/${workerId}/WorkDay/createWorkDay`, 
        {date: {day, month, year}, workSiteId: localCurrWorkSite, payment, description}, 
        { withCredentials: true });
        if (response.status === 200) {
          toast({
            title: 'יום עבודה הוסף בהצלחה',
            status: 'success',
            duration: 5000,
            position: 'top-right',
            isClosable: true,
          });
          yesFunction();
          onClose();
        }
      } catch (error:any) {
        console.error('Error fetching data:', error);
        toast({
          title: 'חוסר/טעות בנתונים',
          description: error?.response?.data?.message,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      } finally { setIsLoading(false); }
  }

  return (
    <Box>
        <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontSize={{base: 'medium', md: 'x-large'}} display="flex" justifyContent="center" alignItems="center" fontWeight="bold">{title}</ModalHeader>
              <ModalCloseButton />
                <ModalBody dir='rtl'>
                  <FormControl>
                      <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">תאריך יום עבודה</FormLabel>
                      <Stack spacing={4} direction={{ base: 'column-reverse', md: 'row'}} >
                          <Stack direction='row'>
                              <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שנה:</FormLabel>
                              <Input value={year} type='number' onChange={(e) => setYear(Number(e.target.value))} />
                          </Stack>
                          <Stack direction='row'>
                              <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חודש:</FormLabel>
                              <Input value={month} type='number' onChange={(e) => setMonth(Number(e.target.value))} />
                          </Stack>
                          <Stack direction='row'>
                              <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יום:</FormLabel>
                              <Input value={day} type='number' onChange={(e) => setDay(Number(e.target.value))} />
                          </Stack>
                      </Stack>
                  </FormControl><br/> 
                  
                  <FormControl>
                    <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">תשלום</FormLabel>
                    <Input value={payment} type='number' onChange={(e) => setPayment(Number(e.target.value))} />
                  </FormControl>

                  <FormControl>
                      <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">א.בניה</FormLabel>
                      <Select value={localCurrWorkSite} onChange={(e:any) => {setLocalCurrWorkSite(e.target.value)}}  dir='ltr' placeholder="תבחור סוג החופש" size='lg'>
                        {workSites?.map((project:any) => (
                         <option key={project._id} value={project._id}>
                            {project.name} 
                          </option> 
                        ))}
                      </Select>
                  </FormControl><br/>

                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">תיאור</FormLabel>
                  <Textarea value={description} onChange={(e) => setDescription(e.target.value)} />
                  
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme='red' mr={3} onClick={onClose}>
                    ביטול
                  </Button>
                  {isLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
                  <Button colorScheme='green' onClick={handleSubmit}>הוספה</Button>)}
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Box>
  )
}

export default AddWorkDayModal;
