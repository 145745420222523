import { Box, Button, Flex, Heading, Icon, IconButton, Input, Menu, MenuButton, MenuItem, MenuList, Select, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tag, TagLabel, Tbody, Td, Text, Thead, Tooltip, Tr, useColorMode, useToast } from '@chakra-ui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { FaEdit } from 'react-icons/fa'
import { MdDeleteOutline, MdOutlinePayments } from 'react-icons/md';
import { FiPlus } from 'react-icons/fi';
import { SiTask } from 'react-icons/si'
import { BiTask } from 'react-icons/bi'
import AddProductModal from '../../components/Modals/addProductModal';
import AddProjectPaymentModal from '../../components/Modals/ProjectModals/addProjectPaymentModal';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteConfirmationDialog from '../../components/Dialogs/DeleteConfirmationDialog';
import AddTaskModal from '../../components/Modals/ProjectModals/addTaskModal';
import { useAuth } from '../../Auth/AuthContext';
import { url } from '../../assets/urlAdress';
import { AiOutlineFilter } from 'react-icons/ai';
import { GrPowerCycle } from 'react-icons/gr';

interface Project {
  _id: any;
  name: String;
  location: String;
  status: String;
  contact: any;
  startDate: any;
  planEndDate: any;
  endDate: any;
  assessment: number;
  expenses: number;
  earn: number;
  materials: any;
  tasks: any;
  workDays: any;
  payments: any;
}

function ProjectDetails() {
    const { setIsAuthenticated } = useAuth();
  const { colorMode  } = useColorMode();
  const headerColor = colorMode === "dark" ? "black" : "#EFEFEFF6";
  const projectId = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState<Project>();
  const [suppliers, setSuppliers] = useState<any[]>();
  const [workDays, setWorkDays] = useState<any[]>();
  const toast = useToast();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${url}/Projects/${projectId.id}`, { withCredentials: true });
      const suppliersList = await axios.get(`${url}/Suppliers`, { withCredentials: true });
      const workDaysData = await axios.get(`${url}/WorkDays/project/${projectId.id}`, { withCredentials: true })
      const data = response.data;
      setProject(data);
      setSuppliers(suppliersList.data)
      setWorkDays(workDaysData.data)
      setIsLoading(false);
    } catch (error:any) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      if (error?.response?.status === 401) { setIsAuthenticated(false); }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const deletePaymentSubmit = async (itemId: any) => {
    try {
        const response = await axios.delete(`${url}/Projects/${projectId.id}/Payments/delete/${itemId}`, { withCredentials: true });
        if (response.status === 200) {
          toast({
            title: 'תשלום נמחק',
            status: 'success',
            duration: 5000,
            position: 'top-right',
            isClosable: true,
          });
          fetchData()
        }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'Error',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }
  const deleteMaterialSubmit = async (itemId: any) => {
    try {
      const response = await axios.delete(`${url}/Projects/${projectId.id}/materials/delete/${itemId}`, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'מוצר נמחק',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        fetchData()
      }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'Error',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }
  const deleteTaskSubmit = async (itemId : any) => {
    try {
      const response = await axios.delete(`${url}/Projects/${projectId.id}/Tasks/delete/${itemId}`, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'משימה נמחקה בהצלחה',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        fetchData()
      }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'Error',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const handleAddProduct = async (data:any) => {
    try {
      let { date, product, supplier } = data;
      const response = await axios.post(`${url}/Projects/${projectId.id}/materials/create`, { date, product, supplier }, { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'מוצר הוסף בהצלחה',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      }
      fetchData();
    } catch (error:any) {
      console.log('Error: ' + error)
      toast({
        title: 'חוסר/טעות בנתונים',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const [addProductModal, setAddProductModal] = useState(false);
  const [addPaymentModal, setAddPaymentModal] = useState(false);
  const [addTaskModal, setAddTaskModal] = useState(false);
  
  const [deletePayment, setDeletePayment] = useState(false);
  const [deleteMaterial, setDeleteMaterial] = useState(false);
  const [deleteTask, setDeleteTask] = useState(false);

  const [currWorker, setCurrWorker] = useState(null);

  const [day, setDay] = useState(0);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);

  const sumOfWorkDays = workDays?.filter((workDay:any) => {
    if (day !== null && day !== 0 && workDay?.Date?.day !== day) {
      return false;
    }
    if (month !== null && month !== 0 && workDay?.Date?.month !== month) {
      return false;
    }
    if (year !== null && year !== 0 && workDay?.Date?.year !== year) {
      return false;
    }
    if (currWorker !== null && currWorker !== workDay?.worker?.name) {
      return false;
    }
    return true
  }).reduce((sum:any, workDay:any) => sum + workDay.payment, 0);

  const sumOfProducts = project?.materials.filter((product:any) => {
    if (day !== null && day !== 0 && product?.Date?.day !== day) {
      return false;
    }
    if (month !== null && month !== 0 && product?.Date?.month !== month) {
      return false;
    }
    if (year !== null && year !== 0 && product?.Date?.year !== year) {
      return false;
    }
    return true
  }).reduce((sum:any, product:any) => sum + (product?.Price * (1-(product.deduction/100)) * product.quantity) , 0);

  return (
    <Box dir="rtl">
      {isLoading ? (
      <Box
        position="fixed"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        padding="2rem"
        borderRadius="8px"
        zIndex="1000">
        <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
      </Box>
        ) : (
        <>
      <AddProductModal title={'הוספת מוצר'} isOpen={addProductModal} onClose={()=>{setAddProductModal(false)}} 
        yesFunction={handleAddProduct} showCar={false} showSupplier={true} showWorkSite={false} suppliers={suppliers} worksites={[]} cars={[]}/>
      <AddProjectPaymentModal url={`${url}/Projects/${projectId.id}/Payments/create`} ifJustCheck={false}
        title={`הוספת משכורת ל: ${project?.name}`} isOpen={addPaymentModal} onClose={() => setAddPaymentModal(false)} yesFunction={fetchData}/>
      <AddTaskModal isOpen={addTaskModal} onClose={()=>setAddTaskModal(false)} projectId={projectId} yesFunction={fetchData} />
      <Flex justifyContent="space-between">
        <Heading as='h3' size='lg'>{project?.name}</Heading>
        <Stack direction={{ base: 'column', md: 'row' }} spacing={3}>
          <Stack direction='row' spacing={3}>
            <Button sx={{ borderRadius: 35 }} onClick={() => navigate(`/Projects/Edit/${projectId.id}`)}>
              <Icon as={FaEdit} sx={{fontSize: '20', display: {base: 'none', sm: 'block'}}}/>
              <Icon as={FaEdit} sx={{fontSize: '20', display: {base: 'block', sm: 'none'}}}/>
              <Text sx={{ fontFamily: 'Arial', mr: 2, display: {base: 'none', sm: 'block'}}}>עריכה</Text>
            </Button>
            <Button sx={{borderRadius: 50}} onClick={()=>setAddTaskModal(true)}>
              <Icon as={FiPlus} sx={{fontSize: '20', display: {base: 'none', sm: 'block'}}}/>
              <Icon as={BiTask} sx={{fontSize: '20', display: {base: 'block', sm: 'none'}}}/>
              <Text sx={{ fontFamily:'Arial', display: {base: 'none', sm: 'block'} }}>הוספת משימה</Text>
            </Button>
          </Stack>
          <Stack direction='row' spacing={3}>
            <Button sx={{borderRadius: 50}} onClick={() => setAddProductModal(true)}>
              <Icon as={FiPlus} sx={{fontSize: '20', display: {base: 'none', sm: 'block'}}}/>
              <Icon as={SiTask} sx={{fontSize: '20', display: {base: 'block', sm: 'none'}}}/>
              <Text sx={{ fontFamily:'Arial', display: {base: 'none', sm: 'block'} }}>הוספת חומרים</Text>
            </Button>
            <Button sx={{borderRadius: 50}} onClick={() => setAddPaymentModal(true)}>
              <Icon as={FiPlus} sx={{fontSize: '20', display: {base: 'none', sm: 'block'}}}/>
              <Icon as={MdOutlinePayments} sx={{fontSize: '20', display: {base: 'block', sm: 'none'}}}/>
              <Text sx={{ fontFamily:'Arial', display: {base: 'none', sm: 'block'} }}>הוספת תשלום</Text>
            </Button>
          </Stack>
        </Stack>

      </Flex><br/>
      <Flex justifyContent="space-between" direction={{ base: 'column', md: 'row' }}>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>שם פרויקט: {project?.name}</Text>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>מיקום: {project?.location}</Text>
        <Tag
          borderRadius='full'
          size="md"
          colorScheme={
          project?.status === 'Completed' ? 'green'
          : project?.status === 'Canceled'? 'red'
          : project?.status === 'On Hold' ? 'orange' 
          : 'blue'}>
            <TagLabel fontSize='medium'>
              מצב: {project?.status === 'Completed' ? 'הושלם'
              : project?.status === 'Canceled' ? 'מבוטל'
              : project?.status === 'On Hold' ? 'בהשהיה' : 'בתהליך'}
            </TagLabel>
        </Tag>

        <Tooltip
          label={
            <Box dir="rtl">
              מנהל: {project?.contact.manger.name}, מס": {project?.contact.manger.phoneNumber} <br/>
              עוזר: {project?.contact.secretary.name}, מס": {project?.contact.secretary.phoneNumber}
            </Box>
          }
          fontSize="md"
          hasArrow
          placement="top">
          <Text fontWeight="bold" fontSize="lg" fontFamily="arial">איש קשר</Text>
        </Tooltip>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>תכנון לסיום: {project?.planEndDate.day}/{project?.planEndDate.month}/{project?.planEndDate.year}</Text>
      </Flex>
      <Flex justifyContent="space-between" direction={{ base: 'column', md: 'row' }}>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>תאריך תחילה: {project?.startDate.day}/{project?.startDate.month}/{project?.startDate.year}</Text>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>תאריך סיום {project?.endDate?.day}/{project?.endDate?.month}/{project?.endDate?.year}</Text>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>הערכה: {project?.assessment}₪</Text>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>הוצאות: {project?.expenses}₪</Text>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>הכנסות: {project?.earn}₪</Text>
      </Flex><br/>
      <Tabs isFitted>
        <TabList>
          <Tab>משימות</Tab>
          <Tab>ימי עבודה</Tab>
          <Tab>חומרים</Tab>
          <Tab>תשלומים</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Table>
              <Thead sx={{bgColor: headerColor}}>
                <Tr>
                  <Td>מס"</Td>
                  <Td>פרוים</Td>
                  <Td>יח'</Td>
                  <Td>כמות</Td>
                  <Td>מחיר</Td>
                  <Td>סה"כ</Td>
                  <Td>מחיקה</Td>
                </Tr>
              </Thead>
              <Tbody>
                {project?.tasks.map((task:any, index:number) => (
                  <Tr key={task._id}>
                    <Td>{index + 1}</Td>
                    <Td>{task.description}</Td>
                    <Td>{task.quantity}</Td>
                    <Td>{task.unit}</Td>
                    <Td>{task.price > 0 ? task.price : ''}</Td>
                    <Td>{task.price > 0 ? (task.price * task.quantity) : ''}₪</Td>
                    <Td>
                      <IconButton
                        colorScheme='red'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<MdDeleteOutline/>}
                        onClick={() => setDeleteTask(task._id)}
                      />
                    </Td>
                    {deleteTask === task._id && <DeleteConfirmationDialog
                      title={`למחוק המשימה  ${task.description}`}
                      isOpen={deleteTask}
                      onClose={()=>setDeleteTask(false)}
                      yesFunction={deleteTaskSubmit}
                      itemId={task._id}
                    />}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TabPanel>
          <TabPanel>
            <Stack spacing={3} direction='row'>
              <Input placeholder="שנה" value={year !== 0 ? year : ''} onChange={(e)=> setYear(Number(e.target.value))}/>
              <Select value={month} dir='ltr' onChange={(e)=> setMonth(Number(e.target.value))}>
                <option value={0}>חודש</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12</option>
              </Select>
              <Input placeholder="יום" value={day !== 0 ? day : ''} onChange={(e)=> setDay(Number(e.target.value))}/>
              <IconButton onClick={() => {setMonth(0); setYear(0); setDay(0);}} bgColor='white' aria-label='Call Sage' fontSize='20px' icon={<GrPowerCycle/>}/>
            </Stack> <br/>
            <TableContainer pr={3}>
              <Table variant='simple'>
                <Thead sx={{bgColor: headerColor}}>
                    <Tr>
                      <Td>
                        <Menu>
                          <MenuButton cursor="pointer">
                            <Stack direction='row-reverse'>
                              <span>{currWorker ? currWorker : 'עובד'}</span>
                              <AiOutlineFilter/>
                            </Stack>
                          </MenuButton>
                          <MenuList>
                            <MenuItem onClick={()=>setCurrWorker(null)}>הכל</MenuItem>
                            {workDays?.map((workDay:any) => (
                              <MenuItem onClick={()=>setCurrWorker(workDay?.worker?.name)}>{workDay?.worker?.name}</MenuItem>
                            ))}
                          </MenuList>
                        </Menu>
                      </Td>
                      <Td fontWeight="bold">תאריך</Td>
                      <Td fontWeight="bold">תשלום</Td>
                      <Td fontWeight="bold">תיאור</Td>
                    </Tr>
                </Thead>
                <Tbody>
                  {workDays?.filter((workDay:any) => {
                    if (day !== null && day !== 0 && workDay?.Date?.day !== day) {
                      return false;
                    }
                    if (month !== null && month !== 0 && workDay?.Date?.month !== month) {
                      return false;
                    }
                    if (year !== null && year !== 0 && workDay?.Date?.year !== year) {
                      return false;
                    }
                    if (currWorker !== null && currWorker !== workDay?.worker?.name) {
                      return false;
                    }
                    return true
                  }).map((workDay:any) => (
                  <Tr key={workDay._id}>
                    <Td>{workDay?.worker?.name}</Td>
                    <Td>{workDay?.Date?.day}/{workDay?.Date?.month}/{workDay?.Date?.year}</Td>
                    <Td>{workDay?.payment}₪</Td>
                    <Td>
                      <Tooltip
                        label={
                          <Box dir="rtl">
                            {workDay?.description}
                          </Box>
                        }
                        fontSize="md"
                        hasArrow
                        placement="top">
                        <Text fontWeight="bold" fontSize="lg" fontFamily="arial">תיאור</Text>
                      </Tooltip>
                    </Td>
                  </Tr>
                  ))}
                  <Tr>
                    <Td></Td>
                    <Td fontWeight='bold'>סכום:</Td>
                    <Td fontWeight='bold'>{sumOfWorkDays}₪</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <Stack spacing={3} direction='row'>
              <Input placeholder="שנה" value={year !== 0 ? year : ''} onChange={(e)=> setYear(Number(e.target.value))}/>
              <Select value={month} dir='ltr' onChange={(e)=> setMonth(Number(e.target.value))}>
                <option value={0}>חודש</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12</option>
              </Select>
              <Input placeholder="יום" value={day !== 0 ? day : ''} onChange={(e)=> setDay(Number(e.target.value))}/>
              <IconButton onClick={() => {setMonth(0); setYear(0); setDay(0);}} bgColor='white' aria-label='Call Sage' fontSize='20px' icon={<GrPowerCycle/>}/>
            </Stack> <br/>
            <Table>
              <Thead sx={{bgColor: headerColor}}>
                <Tr>
                  <Td fontWeight="bold">תאריך</Td>
                  <Td fontWeight="bold">ספאק</Td>
                  <Td fontWeight="bold">שם מוצר</Td>
                  <Td fontWeight="bold">מספר פריט</Td>
                  <Td fontWeight="bold">מחיר ליחיד</Td>
                  <Td fontWeight="bold">%</Td>
                  <Td fontWeight="bold">מחיר נטו</Td>
                  <Td fontWeight="bold">כמות</Td>
                  <Td fontWeight="bold">סב"כ בש"ח</Td>
                  <Td fontWeight="bold">כולל מע"ם</Td>
                  <Td fontWeight="bold">מחיקה</Td>
                </Tr>
              </Thead>
              <Tbody>
                {project?.materials.filter((product:any) => {
                  if (day !== null && day !== 0 && product?.Date?.day !== day) {
                    return false;
                  }
                  if (month !== null && month !== 0 && product?.Date?.month !== month) {
                    return false;
                  }
                  if (year !== null && year !== 0 && product?.Date?.year !== year) {
                    return false;
                  }
                  return true
                }).map((product:any) => (
                  <Tr>
                    <Td>{product.Date.day}/{product.Date.month}/{product.Date.year}</Td>
                    <Td>{product.supplier}</Td>
                    <Td>{product.name}</Td>
                    <Td>{product.Code}</Td>
                    <Td>{product.Price}₪</Td>
                    <Td>{product.deduction}</Td>
                    <Td>{Number(product.Price * (1 - (product.deduction/100)))}₪</Td>
                    <Td>{product.quantity}</Td>
                    <Td>{Number(product.Price * (1 - (product.deduction/100)) * product.quantity)}₪</Td>
                    <Td>{Number(product.Price * (1 - (product.deduction/100)) * product.quantity * 1.17).toFixed(2)}₪</Td>
                    <Td>
                      <IconButton
                        colorScheme='red'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<MdDeleteOutline />}
                        onClick={()=> setDeleteMaterial(product._id)}
                      />
                    </Td>
                    {deleteMaterial === product._id && <DeleteConfirmationDialog
                      title={`למחוק המוצר מ ${product.name}`}
                      isOpen={deleteMaterial}
                      onClose={()=>setDeleteMaterial(false)}
                      yesFunction={deleteMaterialSubmit}
                      itemId={product._id}
                    />}
                  </Tr>
                ))}
                <Tr>
                  <Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td>
                  <Td fontWeight='bold'>סכום:</Td>
                  <Td fontWeight='bold'>{sumOfProducts * 1.17}₪</Td>
                </Tr>
              </Tbody>
            </Table>
          </TabPanel>
          <TabPanel>
            <Table>
              <Thead sx={{bgColor: headerColor}}>
                  <Tr>
                    <Td fontWeight="bold">סוג תשלום</Td>
                    <Td fontWeight="bold">בנק</Td>
                    <Td fontWeight="bold">חשבון</Td>
                    <Td fontWeight="bold">תאריך</Td>
                    <Td fontWeight="bold">סכום</Td>
                    <Td fontWeight="bold">איש</Td>
                    <Td fontWeight="bold">פעולות</Td>
                  </Tr>
              </Thead>
              <Tbody>
                {project?.payments.map((payment:any) => (
                  <Tr>
                    <Td>
                      {payment.paymentType === 'check' ? `ציק- ${payment.payment.number}` : 'העברה בנקאית'}
                    </Td>
                    <Td>{payment.payment.bank} - {payment.payment.branch}</Td>
                    <Td>{payment.payment.account}</Td>
                    <Td>{payment.payment.Date.day}/{payment.payment.Date.month}/{payment.payment.Date.year}</Td>
                    <Td>{payment.payment.price}₪</Td>
                    <Td>{payment.payment.person}</Td>
                    <Td>
                      <IconButton
                        colorScheme='red'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<MdDeleteOutline />}
                        onClick={() => setDeletePayment(payment.id)}
                      />
                    </Td>
                    {deletePayment === payment.id && <DeleteConfirmationDialog
                      title={`למחוק התשלום מ ${payment.payment.account}`}
                      isOpen={deletePayment}
                      onClose={()=>setDeletePayment(false)}
                      yesFunction={deletePaymentSubmit}
                      itemId={payment.id}
                    />}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TabPanel>
        </TabPanels>
      </Tabs>
      </>)}
    </Box>
  )
}

export default ProjectDetails;
