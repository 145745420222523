import React from 'react';
import { Box, ChakraProvider, extendTheme, } from '@chakra-ui/react'
import Sidebar from './components/layout/sideBar/sideBar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Import Pages
import Home from './pages/Home';
import Workers from './pages/workers/workers';
import WorkerDetails from './pages/workers/workerDetails';
import Cars from './pages/cars/cars';
import CarDetails from './pages/cars/carDetails';
import Projects from './pages/projects/projects';
import ProjectDetails from './pages/projects/projectDetails';
import Suppliers from './pages/Suppliers/Suppliers';
import SupplierDetails from './pages/Suppliers/SupplierDetails';
import SupplierOrder from './pages/Suppliers/SupplierOrder';
import CreateProject from './pages/projects/createProject';
import CreateWorker from './pages/workers/createWorker';
import CreateCar from './pages/cars/createCar';
import CreateSupplier from './pages/Suppliers/createSupplier';
import CreateSupplierOrder from './pages/Suppliers/createSupplierOrder';
import Login from './pages/login';
import PrivateRoutes from './Auth/PrivateRoute'
import { useAuth } from './Auth/AuthContext';
import EditCar from './pages/cars/editCar';
import EditWorker from './pages/workers/editWorker';
import EditSupplier from './pages/Suppliers/EditSupplier';
import EditProject from './pages/projects/EditProject';
import Checks from './pages/Checks';
import WorkDaysAllWorkers from './pages/workers/workDaysAllWorkers';
import CarUsageAllCars from './pages/cars/CarUsageAllCars';
import EditSupplierOrder from './pages/Suppliers/editSupplierOrder';

const theme = extendTheme({
  config: {
    useSystemColorMode: true, // Enable system color mode detection
  },
});

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Box minH="100vh">
          {isAuthenticated && <Sidebar />}
          <Box ml={{ base: 0, md: 60 }} p="4">    
            <Routes>
              <Route element={<PrivateRoutes/>}>
                <Route path="/"      element={<Projects/>} />
                {/* Project Routes */}
                <Route path="/Projects"               element={<Projects/>} />
                <Route path="/Projects/:id"           element={<ProjectDetails/>} />
                <Route path="/Projects/Edit/:id"      element={<EditProject/>} />
                <Route path="/Projects/CreateProject" element={<CreateProject/>} />
    
                {/* Worker Routes */}
                <Route path="/Workers"                   element={<Workers/>} />
                <Route path="/Workers/:id"               element={<WorkerDetails/>} />
                <Route path="/Workers/CreateWorker"      element={<CreateWorker/>} />
                <Route path="/Workers/workDayAllWorkers" element={<WorkDaysAllWorkers/>} />
                <Route path="/Workers/Edit/:id"          element={<EditWorker/>} />

    
                {/* Cars Routes */}
                <Route path="/Cars"                  element={<Cars/>} />
                <Route path="/Cars/:id"              element={<CarDetails/>} />
                <Route path="/Cars/CreateCar"        element={<CreateCar/>} />
                <Route path="/Cars/CarUsageAllCars"  element={<CarUsageAllCars/>} />
                <Route path="/Cars/Edit/:id"         element={<EditCar/>} />
    
                {/* Supplier Routes */}
                <Route path="/Suppliers"                         element={<Suppliers/>} />
                <Route path="/Suppliers/:id"                     element={<SupplierDetails/>} />
                <Route path="/Suppliers/CreateSupplier"          element={<CreateSupplier/>} />
                <Route path="/Suppliers/Edit/:id"                element={<EditSupplier/>} />
                <Route path="/Suppliers/:id/:orderId"            element={<SupplierOrder/>} />
                <Route path="/Suppliers/:id/:orderId/Edit"       element={<EditSupplierOrder/>} />
                <Route path="/Suppliers/:id/CreateSupplierOrder" element={<CreateSupplierOrder/>} />
    
                {/* Allowance Routes */}
                <Route path="/Checks" element={<Checks/>} />
              </Route>
              <Route path="/login"    element={<Login/>} />
            </Routes>
          </Box>    
        </Box>
      </Router>
    </ChakraProvider>
  );
}

export default App;