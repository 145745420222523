import { Box, Button, Flex, Heading, Icon, Spinner, Stack, Table, Tag, TagLabel, Tbody, Td, Text, Thead, Tr, useColorMode } from '@chakra-ui/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaEdit } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';
import { url } from '../../assets/urlAdress';

function calculateOrderTotal(order:any) {
  let total = 0;

  for (const product of order.products) {
    const pricePerUnit = product.Price;
    const quantity = product.quantity;
    const discount = (1 - (product.deduction/100));
    const productTotal = pricePerUnit * discount * quantity;
    total += productTotal;
  }

  return total;
}

function SupplierOrder() {
  const { setIsAuthenticated } = useAuth();
  const parmsId = useParams();
  const navigate = useNavigate();

  const { colorMode  } = useColorMode();
  const headerColor = colorMode === "dark" ? "black" : "#EFEFEFF6";
  const [isLoading, setIsLoading] = useState(true);
  const [orderData, setOrderData] = useState<any>()

  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}/Suppliers/${parmsId.id}/getSupplierOrder/${parmsId.orderId}`, { withCredentials: true });
      const data = response.data;
      setOrderData(data);
      setIsLoading(false);
      console.log(data)
    } catch (error:any) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      if (error?.response?.status === 401) { setIsAuthenticated(false); }
    }
  };
    useEffect(() => {
    fetchData();
  }, []);
    
  return (
    <Box dir='rtl'>
      {isLoading ? (
            <Box
              position="fixed"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              padding="2rem"
              borderRadius="8px"
              zIndex="1000"
            >
              <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
            </Box>
        ): (
          <>
      <Flex justifyContent="space-between">
        <Heading as='h3' size='lg'>{orderData.invoicingNum}</Heading>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={3}>
            <Button sx={{ borderRadius: 35 }} onClick={()=>navigate(`/Suppliers/${parmsId.id}/${parmsId.orderId}/Edit`)}>
              <Icon as={FaEdit} sx={{fontSize: '20'}}/>
              <Text sx={{ fontFamily: 'Arial', mr: 2, }}>עריכה</Text>
            </Button>
          </Stack>
      </Flex><br/>
      <Flex justifyContent="space-between" direction={{ base: 'column', md: 'row' }}>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>מס" הזמנה: {orderData.invoicingNum}</Text>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>ספאק: {orderData.supplier.name}</Text>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>תאריך: {orderData?.Date?.day}/{orderData?.Date?.month}/{orderData?.Date?.year}</Text>
        <Tag
          size='lg'
          colorScheme={Number(calculateOrderTotal(orderData) * 1.17).toFixed(2) === Number(orderData.Payed).toFixed(2) ? 'green' : 'red'}
          borderRadius='full'>
          <TagLabel>
            שולם: {Number(orderData.Payed).toFixed(2)}/{Number(calculateOrderTotal(orderData)*1.17).toFixed(2)}
          </TagLabel>
        </Tag>
      </Flex><br/>
      <Table>
        <Thead sx={{bgColor: headerColor}}>
            <Tr>
                <Td fontWeight="bold">שם מוצר</Td>
                <Td fontWeight="bold">מספר פריט</Td>
                <Td fontWeight="bold">מחיר ליחיד</Td>
                <Td fontWeight="bold">%</Td>
                <Td fontWeight="bold">מחיר נטו</Td>
                <Td fontWeight="bold">כמות</Td>
                <Td fontWeight="bold">סב"כ בש"ח</Td>
            </Tr>
        </Thead>
        <Tbody>
            {orderData.products.map((product:any) => (
                <Tr key={product.productNumber}>
                    <Td>{product.name}</Td>
                    <Td>{product.Code}</Td>
                    <Td>{product.Price}₪</Td>
                    <Td>{product.deduction}</Td>
                    <Td>{product.Price * (1 - (product.deduction/100))}₪</Td>
                    <Td>{product.quantity}</Td>
                    <Td>{product.Price * (1 - (product.deduction/100)) * product.quantity}₪</Td>
                </Tr>
            ))}
            <Tr><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td></Tr>
            <Tr><Td></Td><Td></Td><Td></Td><Td></Td>
              <Td fontWeight="bold" fontSize='large'>סכ"ה בש"ח</Td><Td fontWeight="bold" fontSize='large'>סכ"ה בש"ח</Td>
              <Td fontWeight="bold" fontSize='large'>{calculateOrderTotal(orderData)}₪</Td>
            </Tr>
            <Tr><Td></Td><Td></Td><Td></Td><Td></Td>
              <Td fontWeight="bold" fontSize='large'>סכ"ה בש"ח</Td><Td fontWeight="bold" fontSize='large'>מע"ם</Td>
              <Td fontWeight="bold" fontSize='large'>{Number(calculateOrderTotal(orderData) * 0.17).toFixed(2)}₪</Td>
            </Tr>
            <Tr><Td></Td><Td></Td><Td></Td><Td></Td>
              <Td fontWeight="bold" fontSize='large'>סכ"ה בש"ח</Td><Td fontWeight="bold" fontSize='large'>לתשלום</Td>
              <Td fontWeight="bold" fontSize='large'>{Number(calculateOrderTotal(orderData) * 1.17).toFixed(2)}₪</Td>
            </Tr>
        </Tbody>
      </Table>
      </>)}
    </Box>
  )
}

export default SupplierOrder
