import { Box, Button, Flex, Heading, Icon, IconButton, Input, InputGroup, InputLeftElement, Select, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Table, Tabs, Tag, TagLabel, Tbody, Td, Text, Thead, Tr, useColorMode, useToast } from '@chakra-ui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiOutlineEye, AiOutlinePlus } from 'react-icons/ai'
import { FaEdit, FaSearch } from 'react-icons/fa'
import { MdDeleteOutline, } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import DeleteConfirmationDialog from '../../components/Dialogs/DeleteConfirmationDialog'
import AddProjectPaymentModal from '../../components/Modals/ProjectModals/addProjectPaymentModal'
import { FiPlus } from 'react-icons/fi'
import { useAuth } from '../../Auth/AuthContext';
import { url } from '../../assets/urlAdress';
import { GrPowerCycle } from 'react-icons/gr'

interface Supplier {
  _id: any;
  name: string;
  phoneNumber: string;
  companyNumber: string;
  dept: number;
  allOrders: any;
  allPayments: any;
}

function calculateOrderTotal(order:any) {
  let total = 0;
  for (const product of order.products) {
    const pricePerUnit = product.Price;
    const quantity = product.quantity;
    const discount = (1 - (product.deduction/100));
    const productTotal = pricePerUnit * discount * quantity;
    total += productTotal;
  }
  return total;
}

function SupplierDetails() {
  const { setIsAuthenticated } = useAuth();
  const supplierId = useParams();
  const navigate = useNavigate();
  const toast = useToast()
  const { colorMode  } = useColorMode();
  const headerColor = colorMode === "dark" ? "black" : "#EFEFEFF6";
  const [searchInput, setSearchInput] = useState('');
  const [supplier, setSupplier] = useState<Supplier>();
  const [isLoading, setIsLoading] = useState(true);
  
  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}/Suppliers/${supplierId.id}`, { withCredentials: true });
      const data = response.data;
      setSupplier(data);
      console.log(data)
      setIsLoading(false);
    } catch (error:any) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      if (error?.response?.status === 401) { setIsAuthenticated(false); }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [deleteOrder, setDeleteOrder] = useState(false);
  const [deletePayment, setDeletePayment] = useState(false);
  const [addPaymentModal, setAddPaymentModal] = useState(false)

  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);

  const deleteOrderSubmit = async (itemId:any) => {
    try {
      const response = await axios.delete(`${url}/Suppliers/${supplierId.id}/deleteSupplierOrder/${itemId}`
      , { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'ההזמנה נמחקה בהצלחה',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        fetchData();
      }
    } catch (error:any) {
      console.error('Error creating car:', error);
      toast({
        title: 'Error',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }
  const deletePaymentSubmit = async (itemId: any) => {
    try {
        const response = await axios.delete(`${url}/Suppliers/${supplierId.id}/Payments/delete/${itemId}`, { withCredentials: true });
        if (response.status === 200) {
          toast({
            title: 'תשלום נמחק',
            status: 'success',
            duration: 5000,
            position: 'top-right',
            isClosable: true,
          });
          fetchData()
        }
    } catch (error:any) {
        console.error('Error creating car:', error);
        toast({
          title: 'Error',
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
    }
  }

  const sumOfOrdersPayment = supplier?.allOrders.filter((order:any) => {
    if (month !== null && month !== 0 && order.Date.month !== month) {
      return false;
    }
    if (year !== null && year !== 0 && order.Date.year !== year) {
      return false;
    }
    return(
      order.invoicingNum.toLowerCase().includes(searchInput.toLowerCase())
    );
  }).reduce((sum: number, order: any) => sum + Number(calculateOrderTotal(order) * 1.17), 0);

  const sumOfPayed = supplier?.allOrders.filter((order:any) => {
    if (month !== null && month !== 0 && order.Date.month !== month) {
      return false;
    }
    if (year !== null && year !== 0 && order.Date.year !== year) {
      return false;
    }
    return(
      order.invoicingNum.toLowerCase().includes(searchInput.toLowerCase())
    );
  }).reduce((sum: number, order: any) => sum + order.Payed, 0);

  return (
    <Box dir="rtl">
      {isLoading ? (
        <Box
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          padding="2rem"
          borderRadius="8px"
          zIndex="1000"
        >
        <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
      </Box>
      ) : (
        <>
      <AddProjectPaymentModal url={`${url}/Suppliers/${supplierId.id}/Payments/create`} ifJustCheck={false}
        title={`הוספת משכורת ל: ${supplier?.name}`} isOpen={addPaymentModal} onClose={() => setAddPaymentModal(false)} yesFunction={fetchData}/>
      <Flex justifyContent="space-between">
        <Heading as='h3' size='lg'>{supplier?.name}</Heading>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={3}>
            <Button sx={{ borderRadius: 35 }} onClick={()=>navigate(`/Suppliers/Edit/${supplierId.id}`)}>
              <Icon as={FaEdit} sx={{fontSize: '20'}}/>
              <Text sx={{ fontFamily: 'Arial', mr: 2, display: {base: 'none', sm: 'block'}}}>עריכה</Text>
            </Button>
            <Button sx={{ borderRadius: 35 }} onClick={()=>navigate(`/Suppliers/${supplierId.id}/CreateSupplierOrder`)}>
              <Icon as={AiOutlinePlus} sx={{fontSize: '20', display: {base: 'none', sm: 'block'}}} />
              <Text sx={{ fontFamily: 'Arial', mr: 2, }}>הוספת הזמנה</Text>
            </Button>
            <Button sx={{borderRadius: 50}} onClick={() => setAddPaymentModal(true)}>
              <Icon as={FiPlus} sx={{fontSize: '20', display: {base: 'none', sm: 'block'}}}/>
              <Text sx={{ fontFamily:'Arial',}}>הוספת תשלום</Text>
            </Button>
          </Stack>
      </Flex><br/>
      <Flex justifyContent="space-between" direction={{ base: 'column', md: 'row' }}>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>שם ספק: {supplier?.name}</Text>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>מספר חברה: {supplier?.companyNumber}</Text>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>מס" טל: {supplier?.phoneNumber}</Text>
        <Tag
          size='lg'
          colorScheme={Number(Number(supplier?.dept).toFixed(2)) === 0 ? 'green' : 'red'}
          borderRadius='full'>
          <TagLabel>
            חוב: {Number(supplier?.dept).toFixed(2)}₪
          </TagLabel>
        </Tag>
        <Text fontWeight="bold" fontSize='lg' fontFamily='arial'>הזמנות: {supplier?.allOrders.length}</Text>
      </Flex><br/>
      <Tabs isFitted>
        <TabList>
            <Tab>הזמנות</Tab>
            <Tab>תשלומים</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box>
              <Stack spacing={2} direction='row' >
                <InputGroup width='250%' mr={3}>
                  <InputLeftElement><Icon as={FaSearch} /></InputLeftElement>
                  <Input placeholder='חיפוש' onChange={(e) => setSearchInput(e.target.value)}/>
                </InputGroup><br/>
                <Input placeholder="שנה" value={year !== 0 ? year : ''} onChange={(e)=> setYear(Number(e.target.value))}/>
                <Select value={month} dir='ltr' onChange={(e)=> setMonth(Number(e.target.value))}>
                  <option value={0}>חודש</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                </Select>
                <IconButton onClick={() => {setMonth(0); setYear(0);}} bgColor='white' aria-label='Call Sage' fontSize='20px' icon={<GrPowerCycle/>}/>
              </Stack><br/>
              <Table>
                <Thead sx={{bgColor: headerColor}}>
                  <Tr>
                    <Td fontWeight="bold">מס' הזמנה</Td>
                    <Td fontWeight="bold">זמן ההזמנה</Td>
                    <Td fontWeight="bold">שולם</Td>
                    <Td fontWeight="bold">נשאר לתשלום</Td>
                    <Td fontWeight="bold">פעולות</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {supplier?.allOrders.filter((order:any) => {
                        if (month !== null && month !== 0 && order.Date.month !== month) {
                          return false;
                        }
                        if (year !== null && year !== 0 && order.Date.year !== year) {
                          return false;
                        }
                        return(
                          order.invoicingNum.toLowerCase().includes(searchInput.toLowerCase())
                        );
                      }).map((order:any) => (
                    <Tr key={order._id}>
                      <Td>{order.invoicingNum}</Td>
                      <Td>{order?.Date?.day}/{order?.Date?.month}/{order?.Date?.year}</Td>
                      <Td>{Number(order.Payed).toFixed(2)}/{Number(calculateOrderTotal(order) * 1.17).toFixed(2)}</Td>
                      <Td>
                        <Tag
                          size='lg'
                          colorScheme={ 
                            Number((calculateOrderTotal(order) * 1.17)).toFixed(2) === Number(order.Payed).toFixed(2)
                             ? 'green' : 'red'}
                          borderRadius='full'>
                          <TagLabel>
                            {Number((calculateOrderTotal(order) * 1.17) - order.Payed).toFixed(2)}₪
                          </TagLabel>
                        </Tag>
                      </Td>
                      <Td>
                        <Stack spacing={3} direction='row'>
                          <IconButton
                            colorScheme='teal'
                            aria-label='Call Sage'
                            fontSize='20px'
                            icon={<AiOutlineEye />}
                            onClick={()=>navigate(`/Suppliers/${supplierId.id}/${order._id}`)}
                          />
                          <IconButton
                            colorScheme='red'
                            aria-label='Call Sage'
                            fontSize='20px'
                            icon={<MdDeleteOutline />}
                            onClick={() => setDeleteOrder(order._id)}
                          />
                        </Stack>
                      </Td>
                      {deleteOrder === order._id && <DeleteConfirmationDialog
                        title={`האם אתה רוצה למחוק הזמנה מספר: ${order.invoicingNum}`}
                        isOpen={deleteOrder}
                        onClose={() => setDeleteOrder(false)}
                        yesFunction={deleteOrderSubmit}
                        itemId={order._id}
                      />}
                    </Tr>
                  ))}
                  <Tr>
                    <Td></Td>
                    <Td fontWeight='bold'>סכום:</Td>
                    <Td fontWeight='bold'>{Number(sumOfOrdersPayment).toFixed(2)}₪</Td>
                    <Td fontWeight='bold'>
                      <Tag
                        size='lg'
                        colorScheme={ 
                          (sumOfOrdersPayment - sumOfPayed) === 0
                           ? 'green' : 'red'}
                        borderRadius='full'>
                        <TagLabel>
                          {Number(sumOfOrdersPayment - sumOfPayed).toFixed(2)}₪
                        </TagLabel>
                      </Tag>
                    </Td>
                    <Td></Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </TabPanel>
          <TabPanel>
            <Table>
              <Thead sx={{bgColor: headerColor}}>
                <Tr>
                  <Td fontWeight="bold">סוג תשלום</Td>
                  <Td fontWeight="bold">בנק</Td>
                  <Td fontWeight="bold">חשבון</Td>
                  <Td fontWeight="bold">תאריך</Td>
                  <Td fontWeight="bold">סכום</Td>
                  <Td fontWeight="bold">איש</Td>
                  <Td fontWeight="bold">מצב</Td>
                  <Td fontWeight="bold">פעולות</Td>
                </Tr>
              </Thead>
              <Tbody>
                {supplier?.allPayments.map((payment:any) => (
                  <Tr>
                    <Td>
                      {payment.paymentType === 'check' ? `ציק- ${payment.payment.number}` : 'העברה בנקאית'}
                    </Td>
                    <Td>{payment.payment.bank} - {payment.payment.branch}</Td>
                    <Td>{payment.payment.account}</Td>
                    <Td>{payment.payment.Date.day}/{payment.payment.Date.month}/{payment.payment.Date.year}</Td>
                    <Td>{payment.payment.price}₪</Td>
                    <Td>{payment.payment.person}</Td>
                    <Td>
                      <Tag
                        size="lg"
                        colorScheme={payment.payment.paid ? 'green' : 'red'}
                        borderRadius="full">
                        <TagLabel>
                          {payment.payment.paid ? 'שולם' : 'לא שולם'}
                        </TagLabel>
                      </Tag>
                    </Td>
                    <Td>
                      <IconButton
                        colorScheme='red'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<MdDeleteOutline />}
                        onClick={() => setDeletePayment(payment.id)}
                      />
                    </Td>
                    {deletePayment === payment.id && <DeleteConfirmationDialog
                      title={`למחוק התשלום מ ${payment.payment.account}`}
                      isOpen={deletePayment}
                      onClose={()=>setDeletePayment(false)}
                      yesFunction={deletePaymentSubmit}
                      itemId={payment.id}
                    />}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TabPanel>
        </TabPanels>
      </Tabs>
      </>)}
    </Box>
  )
}

export default SupplierDetails
