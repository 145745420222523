import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Thead, Tbody, Tr, Td, Input, Button, Text, Box, Select, Textarea, Spinner, useColorMode, useToast, } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';
import { url } from '../../assets/urlAdress';

interface Worker {
  _id: any;
  name: string;
  phoneNumber: string;
  dailyPayment: number;
  status: string;
  currIncome: number;
  currentWorkSite: any;
  salaries: any;
  workDays: any;
  daysOff: any;
  description: string;
}

function WorkDaysAllWorkers() {
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(true);
  const [workers, setWorkers] = useState<Worker[]>([]);
  const [editedWorkers, setEditedWorkers] = useState<Worker[]>([]);
  const [workSites, setWorkSites] = useState<any[]>();
  const { colorMode  } = useColorMode();
  const headerColor = colorMode === "dark" ? "black" : "#f6f6f6f6";
  
  const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${url}/Workers`, { withCredentials: true });
        const projects = await axios.get(`${url}/Projects`, { withCredentials: true });
        const data = response.data;
        setWorkers(data);
        setEditedWorkers([...data]);
        setWorkSites(projects.data);
        setIsLoading(false);
      } catch (error:any) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
        if (error?.response?.status === 401) { setIsAuthenticated(false); }
      }
    };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (workerIndex: number, field: keyof Worker, value: string) => {
    // Update the edited worker data using the worker's index as the identifier
    const updatedWorkers = [...editedWorkers];
    const editedWorker = { ...updatedWorkers[workerIndex] };
    editedWorker[field] = value as never;
    updatedWorkers[workerIndex] = editedWorker;
    setEditedWorkers(updatedWorkers);
  };

  const [isAddButtonLoading, setIsAddButtonLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsAddButtonLoading(true);
      const response = await axios.post(`${url}/Workers/createWorkDaysForWorkers`, 
      {editedWorkers},
      { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'יום עבודה הוסף בהצלחה',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      }
      navigate('/Workers');
    } catch (error:any) {
      console.error('Error fetching data:', error);
      toast({
        title: 'חוסר/טעות בנתונים',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    } finally { setIsAddButtonLoading(false); }
  };

  return (
    <Box dir='rtl'>
      {isLoading ? (
          <Box
              position="fixed"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              padding="2rem"
              borderRadius="8px"
              zIndex="1000"
          >
             <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
          </Box>
      ) : (
          <Box>
            <Text fontSize='3xl' textAlign='center' fontWeight="bold">הוספת ימי עבודה</Text><br/>
            <Table>
              <Thead sx={{bgColor: headerColor}}>
                <Tr>
                  <Td fontWeight='bold'>עובד</Td>
                  <Td fontWeight='bold'>מספר</Td>
                  <Td fontWeight='bold'>תשלום</Td>
                  <Td fontWeight='bold'>א.בניה</Td>
                  <Td fontWeight='bold'>תיאור</Td>
                </Tr>
              </Thead>
              <Tbody>
                {workers.map((worker, index) => (
                  <Tr key={worker._id}>
                    <Td>{worker.name} </Td>
                    <Td>{worker.phoneNumber}</Td>
                    <Td>
                      <Input
                        type="text"
                        value={editedWorkers[index].dailyPayment}
                        onChange={(e) => handleEdit(index, 'dailyPayment', e.target.value)}
                      />
                    </Td>
                    <Td>
                      <Select value={editedWorkers[index]?.currentWorkSite?._id} onChange={(e) => handleEdit(index, 'currentWorkSite', e.target.value)}
                       dir='ltr' placeholder="תבחור סוג החופש">
                          {workSites?.map((project:any) => (
                            <option value={project._id}>{project.name}</option>
                          ))}
                      </Select>
                    </Td>
                    <Td>
                    <Textarea
                        value={editedWorkers[index].description}
                        onChange={(e) => handleEdit(index, 'description', e.target.value)}/>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <br/>
            {isAddButtonLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
              <Button type='submit' sx={{
                  boxShadow: '10px',
                  backgroundColor: '#4caf50',
                  border: 'none',
                  color: 'white',
                  textAlign: 'center',
                  fontSize: '16px',
                  borderRadius: '20px',
                  width: '100%',
                }} onClick={handleSubmit}>הוספה
              </Button>
            )}
          </Box>
      )}
    </Box>
  );
}

export default WorkDaysAllWorkers
