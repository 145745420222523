import React, { useState } from 'react'
import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, FormControl, FormLabel, Stack, Input, Select, useBreakpointValue, useToast, Spinner } from '@chakra-ui/react'
import axios from 'axios';
import { url } from '../../../assets/urlAdress';

interface WorkerPaymentModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  workerId: String;
  yesFunction: () => void;
  currSalary: Number;
}

function WorkerPaymentModal({ title, isOpen, onClose, workerId, yesFunction, currSalary, }:WorkerPaymentModalProps) {
  const modalSize = useBreakpointValue({ base: "full", md: "3xl" });
  const toast = useToast()

  const [fromDay, setFromDay] = useState((new Date).getDate());
  const [fromMonth, setFromMonth] = useState((new Date).getMonth() + 1);
  const [fromYear, setFromYear] = useState((new Date).getFullYear());

  const [toDay, setToDay] = useState((new Date).getDate());
  const [toMonth, setToMonth] = useState((new Date).getMonth() + 1);
  const [toYear, setToYear] = useState((new Date).getFullYear());

  const [salary, setSalary] = useState(currSalary);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${url}/Workers/${workerId}/salaries/createSalary`, 
      {salary, fromDate: {day: fromDay, month: fromMonth, year: fromYear}, toDate: {day: toDay, month: toMonth, year: toYear}}, 
      { withCredentials: true });
      if (response.status === 200) {
        toast({
          title: 'תשלום לעבודה הוסף בהצלחה',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        yesFunction();
        onClose();
      }
    } catch (error:any) {
      console.error('Error fetching data:', error);
      toast({
        title: 'חוסר/טעות בנתונים',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    } finally { setIsLoading(false); }
  }

  return (
    <Box>
      <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={{base: 'medium', md: 'x-large'}} display="flex" justifyContent="center" alignItems="center" fontWeight="bold">{title}</ModalHeader>
          <ModalCloseButton />
            <ModalBody dir='rtl'>
                <FormControl>
                  <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">משכורת</FormLabel>
                  <Input name='text' type='Number' value={salary.toString()} onChange={(e)=>setSalary(Number(e.target.value))}/>
                </FormControl><br/>

                <FormControl>
                   <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">מתאריך</FormLabel>
                   <Stack spacing={4} direction={{ base: 'column-reverse', md: 'row'}} >
                       <Stack direction='row'>
                           <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שנה:</FormLabel>
                           <Input value={fromYear.toString()} type='number' onChange={(e) => setFromYear(Number(e.target.value))} />
                       </Stack>
                       <Stack direction='row'>
                           <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חודש:</FormLabel>
                           <Input value={fromMonth.toString()} type='number' onChange={(e) => setFromMonth(Number(e.target.value))} />
                       </Stack>
                       <Stack direction='row'>
                           <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יום:</FormLabel>
                           <Input value={fromDay.toString()} type='number' onChange={(e) => setFromDay(Number(e.target.value))} />
                       </Stack>
                   </Stack>
                </FormControl><br/>

                <FormControl>
                   <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">לתאריך</FormLabel>
                   <Stack spacing={4} direction={{ base: 'column-reverse', md: 'row'}} >
                       <Stack direction='row'>
                           <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">שנה:</FormLabel>
                           <Input value={toYear.toString()} type='number' onChange={(e) => setToYear(Number(e.target.value))} />
                       </Stack>
                       <Stack direction='row'>
                           <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">חודש:</FormLabel>
                           <Input value={toMonth.toString()} type='number' onChange={(e) => setToMonth(Number(e.target.value))} />
                       </Stack>
                       <Stack direction='row'>
                           <FormLabel fontSize={{base: 'medium', md: 'x-large'}} fontWeight="bold">יום:</FormLabel>
                           <Input value={toDay.toString()} type='number' onChange={(e) => setToDay(Number(e.target.value))} />
                       </Stack>
                   </Stack>
                </FormControl><br/>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='red' mr={3} onClick={onClose}>
                ביטול
              </Button>
              {isLoading ? ( <Spinner color="green.500" size="xl" thickness="5px"/> ) : (
              <Button colorScheme='green' onClick={handleSubmit}>הוספה</Button>)}
            </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default WorkerPaymentModal
