import { Box, useColorMode, Text, Stack, InputGroup, InputLeftElement, Input, Icon, Button, Table, Thead, Td, Tr, Tbody, IconButton, Tag, TagLabel, Spinner, useToast, } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai';
import { FaSearch } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmationDialog from '../../components/Dialogs/DeleteConfirmationDialog';
import { useAuth } from '../../Auth/AuthContext';
import { url } from '../../assets/urlAdress';
import { BiMenu } from 'react-icons/bi';
import DateToDateModal from '../../components/Modals/dateToDate';


interface Supplier {
    _id: any;
    name: string;
    phoneNumber: string;
    companyNumber: string;
    dept: number;
}

function Suppliers() {
    const { setIsAuthenticated } = useAuth();
    const { colorMode  } = useColorMode();
    const headerColor = colorMode === "dark" ? "black" : "#f6f6f6f6";
    const toast = useToast()

    const [searchInput, setSearchInput] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);
    const navigate = useNavigate();

    const fetchData = async () => {
      try {
          const response = await axios.get(`${url}/Suppliers`, { withCredentials: true });
          const data = response.data;
          setSuppliers(data)
          setIsLoading(false);
      } catch (error:any) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
        if (error?.response?.status === 401) { setIsAuthenticated(false); }

      }
    };
    useEffect(() => {
      fetchData();
    }, []);

    const [currentId, setCurrentId] = useState(false);
    // const [addPaymentModal, setAddPaymentModal] = useState(false)
    const [menuModal, setMenuModal] = useState(false);

    const handleDeleteButtonClick = async (itemId: any) => {
        try {
            const response = await axios.delete(`${url}/Suppliers/${itemId}`, { withCredentials: true });
            if (response.status === 200) {
              toast({
                title: 'ספאק נמחק',
                status: 'success',
                duration: 5000,
                position: 'top-right',
                isClosable: true,
              });
              fetchData();
            }
        } catch (error:any) {
            console.error('Error creating car:', error);
            toast({
              title: 'Error',
              status: 'error',
              duration: 5000,
              position: 'top-right',
              isClosable: true,
            });
        }
    }

    const [ordersMenuList, setOrdersMenuList] = useState<any[]>([]);
    const DateToDateFunction = async (fromDate:any, toDate:any) => {
        try {
            setIsLoading(true);
            const response = await axios.post(`${url}/Suppliers/getOrdersMenu`, {fromDate, toDate} , { withCredentials: true });
            if (response.status === 200) {
                setIsLoading(false);
                setOrdersMenuList(response.data);
                console.log(response.data)
            }
        } catch (error:any) {
            setIsLoading(false);
            toast({
              title: 'Error',
              description: error.response.data.error || 'יש טעות',
              status: 'error',
              duration: 5000,
              position: 'top-right',
              isClosable: true,
            });
        }
    }
    
  return (
    <Box dir="rtl">
        {isLoading ? (
            <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                padding="2rem"
                borderRadius="8px"
                zIndex="1000"
            >
               <Spinner thickness='10px'speed='0.50s'emptyColor='gray.200'color='green' style={{ width: '100px', height: '100px' }}/>
            </Box>
        ) : (
        <>
        <DateToDateModal title='תפריט לספקים' isOpen={menuModal} onClose={() => setMenuModal(false)} yesFunction={DateToDateFunction}/>
        <Box>
            <Text fontSize='3xl' textAlign='center' fontWeight="bold">ספקים</Text><br/>
            <Stack spacing={2} direction='row'>
                <InputGroup mr={3}>
                    <InputLeftElement><Icon as={FaSearch} /></InputLeftElement>
                    <Input placeholder='חיפוש' onChange={(e) => setSearchInput(e.target.value)}/>
                </InputGroup>
                <Button mr={2} colorScheme='teal' sx={{borderRadius: 50}} onClick={()=>navigate('/Suppliers/CreateSupplier')}>
                    <Icon as={FiPlus} sx={{fontSize: '20'}}/>
                    <Text sx={{ fontFamily:'Arial', display: {base: 'none', sm: 'block'} }}>הוספת ספק</Text>
                </Button>
                <Button mr={2} colorScheme='blue' sx={{borderRadius: 50}} onClick={()=>setMenuModal(true)}>
                    <Icon as={BiMenu} sx={{fontSize: '20'}}/>
                    <Text sx={{ fontFamily:'Arial', display: {base: 'none', sm: 'block'} }}>תפריט</Text>
                </Button>
            </Stack>
        </Box> <br/>
        <Box>
            {ordersMenuList.length === 0 ? (
                <Table variant='simple'>
                    <Thead sx={{bgColor: headerColor}}>
                        <Tr>
                            <Td fontWeight="bold">שם ספאק</Td>
                            <Td fontWeight="bold">מס" חברה</Td>
                            <Td fontWeight="bold">מס" טל</Td>
                            <Td fontWeight="bold">חוב</Td>
                            <Td fontWeight="bold">מחיקה</Td>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {suppliers.filter((supplier) => {
                            return (
                                supplier.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                                supplier.companyNumber.toLowerCase().includes(searchInput.toLowerCase())
                                || supplier.phoneNumber.toLowerCase().includes(searchInput.toLowerCase()) 
                            );
                        }).map((supplier:Supplier) => (
                            <Tr key={supplier._id}>
                                <Td>{supplier.name}</Td>
                                <Td>{supplier.companyNumber}</Td>
                                <Td>{supplier.phoneNumber}</Td>
                                <Td>
                                    <Tag
                                        size='lg'
                                        colorScheme={Number(Number(supplier.dept).toFixed(2)) === 0 ? 'green' : 'red'}
                                        borderRadius='full'>
                                        <TagLabel>
                                          {Number(supplier.dept).toFixed(2)}₪
                                        </TagLabel>
                                    </Tag>
                                </Td>
                                <Td>
                                    <Stack spacing={3} direction='row'>
                                        <IconButton
                                         variant='solid'
                                         colorScheme='teal'
                                         aria-label='Call Sage'
                                         fontSize='20px'
                                         icon={<AiOutlineEye />}
                                         onClick={()=>navigate(`/Suppliers/${supplier._id}`)}
                                        />
                                        <IconButton
                                          colorScheme='red'
                                          aria-label='Call Sage'
                                          fontSize='20px'
                                          icon={<MdDeleteOutline />}
                                          onClick={()=>setCurrentId(supplier._id)}
                                        />
                                    </Stack>
                                </Td>
                                {currentId === supplier._id && <DeleteConfirmationDialog
                                    title={`האם אתה רוצה למחוק הספאק: ${supplier.name}`}
                                    isOpen={currentId}
                                    onClose={() => setCurrentId(false)}
                                    yesFunction={handleDeleteButtonClick}
                                    itemId={supplier._id}
                                />}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            ) : (
                <Table>
                <Thead sx={{bgColor: headerColor}}>
                    <Tr>
                        <Td fontWeight="bold">ספאק</Td>
                        <Td fontWeight="bold">שם מוצר</Td>
                        <Td fontWeight="bold">מספר פריט</Td>
                        <Td fontWeight="bold">מחיר ליחיד</Td>
                        <Td fontWeight="bold">%</Td>
                        <Td fontWeight="bold">מחיר נטו</Td>
                        <Td fontWeight="bold">כמות</Td>
                        <Td fontWeight="bold">סב"כ בש"ח</Td>
                    </Tr>
                </Thead>
                <Tbody>
                    {ordersMenuList.map((order:any) => (
                        order.products.map((product:any) => (
                            <Tr key={product.productNumber}>
                                <Td>{order.supplier.name}</Td>
                                <Td>{product.name}</Td>
                                <Td>{product.Code}</Td>
                                <Td>{product.Price}₪</Td>
                                <Td>{product.deduction}</Td>
                                <Td>{product.Price * (1 - (product.deduction/100))}₪</Td>
                                <Td>{product.quantity}</Td>
                                <Td>{product.Price * (1 - (product.deduction/100)) * product.quantity}₪</Td>
                            </Tr>
                        ))
                    ))}
                    <Tr><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td></Tr>
                    <Tr><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td>
                      <Td fontWeight="bold" fontSize='large'>סכ"ה בש"ח</Td><Td fontWeight="bold" fontSize='large'>סכ"ה בש"ח</Td>
                      <Td fontWeight="bold" fontSize='large'>{calculateOrderTotal(ordersMenuList)}₪</Td>
                    </Tr>
                    <Tr><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td>
                      <Td fontWeight="bold" fontSize='large'>סכ"ה בש"ח</Td><Td fontWeight="bold" fontSize='large'>מע"ם</Td>
                      <Td fontWeight="bold" fontSize='large'>{Number(calculateOrderTotal(ordersMenuList) * 0.17).toFixed(2)}₪</Td>
                    </Tr>
                    <Tr><Td></Td><Td></Td><Td></Td><Td></Td><Td></Td>
                      <Td fontWeight="bold" fontSize='large'>סכ"ה בש"ח</Td><Td fontWeight="bold" fontSize='large'>לתשלום</Td>
                      <Td fontWeight="bold" fontSize='large'>{Number(calculateOrderTotal(ordersMenuList) * 1.17).toFixed(2)}₪</Td>
                    </Tr>
                </Tbody>
              </Table>
            )}
        </Box>
        </>  
        )}
    </Box>
  )
}

export default Suppliers

function calculateOrderTotal(orderList:any) {
    let total = 0;
    for (const order of orderList) {
        for (const product of order.products) {
            const pricePerUnit = product.Price;
            const quantity = product.quantity;
            const discount = (1 - (product.deduction/100));
            const productTotal = pricePerUnit * discount * quantity;
            total += productTotal;
          }
    }
    return total;
  }